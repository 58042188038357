import { Link } from "react-router-dom";
import Header from "./Header";

const Register = () => {
  return (
    <>
      <section>
        <Header />
      </section>

      <div className="container ">
        <div className="row reg-container">
          <div className="col-lg-6 left-reg ">
            {/* <img src={require("../assets/register-img.png")} alt="" /> */}
          </div>
          <div className="col-lg-6 right-reg ">
            <h3>Welcome To Enzo !</h3>
            <div className="input-groups mt-4 mb-3">
              <h6 className="input-label mb-3">Email</h6>
              <input
                type="text"
                name="email"
                className="input-field"
                placeholder="Enter your email address"
              />
            </div>{" "}
            <div className="input-groups icons my-4">
              <h6 className="input-label mb-3">Password</h6>
              <div className="password-container">
                <input
                  name="password"
                  minLength={6}
                  maxLength={15}
                  className="input-field"
                  placeholder="Enter your password"
                />{" "}
                <i class="fa-regular fa-eye reg_eye"></i>{" "}
                {/* <i class="fa-regular fa-eye-slash reg_eye"></i> */}
              </div>
            </div>
            <div className="input-groups icons my-4">
              <h6 className="input-label mb-3">Confirm Password</h6>
              <div className="password-container">
                <input
                  name="password"
                  minLength={6}
                  maxLength={15}
                  className="input-field"
                  placeholder="Re-Enter your password"
                />
                <i class="fa-regular fa-eye reg_eye"></i>
                {/* <i class="fa-regular fa-eye-slash reg_eye"></i> */}
              </div>
            </div>
            <div>
              <div className="reg-terms">
                <div className="reg-check-container">
                  <input
                    id="custom-checkbox"
                    className="input-field regular_checkbox"
                    type="checkbox"
                    placeholder="Enter the referral ID (optional)"
                  />
                  <label htmlFor="custom-checkbox"></label>
                </div>
                <label
                  htmlFor="custom-checkbox"
                  className="terms-check terms-check-ml"
                >
                  I agree to the
                  <Link to="" className="terms-check">
                    {" "}
                    terms{" "}
                  </Link>{" "}
                  and{" "}
                  <Link to="" className="terms-check">
                    {" "}
                    Conditions
                  </Link>
                </label>
              </div>
            </div>
            <div className="Submit">
              <button className="primary-btn reg-btn w-100">Sign up</button>
            </div>
            <div className="foot">
              <p>
                Already have an account?{" "}
                <Link to="/login" className="reg-signin">
                  Sign in
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
