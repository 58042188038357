import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import apiService from "../core/sevice/detail";
import { postMethod } from "../core/sevice/common.api";
import toast, { Toaster } from "react-hot-toast";
import { setAuthorization } from "../core/sevice/axios";
import Header from "./Header";

const Login = () => {
  const navigate = useNavigate();

  const initialFormValue = {
    email: "",
    password: "",
  };

  const [emailValidate, setemailValidate, emailValidateref] = useState(false);
  const [passwordValidate, setpasswordValidate, passwordValidateref] =
    useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [passHide, setPasshide] = useState(false);
  const [inputType, setinputType] = useState("password");

  const { email, password } = formValue;

  useEffect(() => {
    var token = sessionStorage.getItem("user_token");
    if (token) {
      navigate("/dashboard");
    }
  }, []);

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    validate(formData);
  };

  const validate = async (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Email is a required field !";
      setemailValidate(true);
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address !";
      setemailValidate(true);
    } else {
      setemailValidate(false);
    }

    if (values.password == "") {
      setpasswordValidate(true);
      errors.password = "password is a required field !";
    } else if (values.password.length < 5 || values.password.length > 25) {
      setpasswordValidate(true);
      errors.password =
        "Password is required and shouldnot below 5 above 25 letters !";
    } else if (!values.password.match(/[a-z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least lower character !";
    } else if (!values.password.match(/[A-Z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least upper character !";
    } else if (!values.password.match(/[0-9]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at One digit character !";
    } else if (!values.password.match(/[!@#$%^&*]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least one special character !";
    } else {
      setpasswordValidate(false);
    }

    setvalidationnErr(errors);
    return errors;
  };

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setinputType("text");
    } else {
      setPasshide(false);
      setinputType("password");
    }
  };

  const formSubmit = async () => {
    validate(formValue);
    if (
      emailValidateref.current === false &&
      passwordValidateref.current === false
    ) {
      console.log(formValue);
      var data = {
        apiUrl: apiService.signin,
        payload: formValue,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      if (resp?.tfa === 1) {
        navigate("/verify-tfa", {
          state: {
            socketToken: resp?.socketToken,
          },
        });
      } else {
        if (resp.status == true) {
          setFormValue(initialFormValue);
          toast.success(resp.Message);
          console.log(resp, "[--=-=resp");
          await setAuthorization(resp.token);
          sessionStorage.setItem("user_token", resp.token);
          sessionStorage.setItem("tfa_status", resp.tfa);
          sessionStorage.setItem("socket_token", resp.socketToken);
          sessionStorage.setItem("jwNkiKmttscotlox", resp.jwNkiKmttscotlox);
          // localStorage.setItem("user_token", resp.token);
          // localStorage.setItem("tfa_status", resp.tfa);
          // localStorage.setItem("socket_token", resp.socketToken);
          // localStorage.setItem("jwNkiKmttscotlox", resp.jwNkiKmttscotlox);
          navigate("/dashboard");
        } else {
          setbuttonLoader(false);
          toast.error(resp.Message);
        }
      }
    }
  };

  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          duration: 4000,
        }}
      />
      <section>
        <Header />
      </section>

      <div className="container ">
        <div className="row reg-container">
          <div className="col-lg-6 left-reg ">
            {/* <img src={require("../assets/register-img.png")} alt="" /> */}
          </div>
          <div className="col-lg-6 right-reg ">
            <h3>Welcome Back !</h3>
            <div className="input-groups mt-4 mb-3">
              <h6 className="input-label mb-3">Email</h6>
              <input
                type="text"
                name="email"
                value={email}
                onChange={handleChange}
                className="input-field"
                placeholder="Enter your email address"
              />
            </div>
            {emailValidate == true ? (
              <p className="errorcss"> {validationnErr.email} </p>
            ) : (
              ""
            )}
            <div className="input-groups icons my-4">
              <h6 className="input-label mb-3">Password</h6>
              <div className="password-container">
                <input
                  type={inputType}
                  name="password"
                  value={password}
                  onChange={handleChange}
                  maxLength={40}
                  className="input-field"
                  placeholder="Enter your password"
                />
                {passHide == false ? (
                  <i
                    class="fa-regular fa-eye-slash reg_eye"
                    onClick={() => passwordHide("hide")}
                  ></i>
                ) : (
                  <i
                    class="fa-regular fa-eye reg_eye"
                    onClick={() => passwordHide("show")}
                  ></i>
                )}{" "}
                {/*  */}
              </div>
            </div>
            {passwordValidate == true ? (
              <p className="errorcss">{validationnErr.password} </p>
            ) : (
              ""
            )}
            <div className="fp-wrapper">
              <Link to="/forgot" className="forget-password">
                Forget Password?
              </Link>
            </div>
            <div className="Submit">
              {buttonLoader == false ? (
                <button
                  className="primary-btn reg-btn w-100"
                  onClick={formSubmit}
                >
                  Sign in
                </button>
              ) : (
                <button className="primary-btn reg-btn w-100">
                  Loading...
                </button>
              )}
            </div>
            {/* <div className="foot">
              <p>
                Don’t have an account?{" "}
                <Link to="/register" className="reg-signin">
                  Sign up
                </Link>
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
