import React, { useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import useState from "react-usestateref";
import LINEAR_BG from "../assets/compress/linear-bg-primary.webp";
import REFERRAL from "../assets/compress/referral-code.webp";
import LAST from "../assets/compress/last-thirty.webp";
import AVERAGE from "../assets/compress/average-profit.webp";
import { balanceHistory } from "../utils/mockData";
import BalanceHistory from "./BalanceHistory";
import apiService from "../core/sevice/detail";
import {
  getMethod,
  postMethod,
  exchangegetMethod,
} from "../core/sevice/common.api";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";

const Dashboard = () => {
  const [isActive, setIsActive] = useState("OFF");
  // console.log(isActive, "------isActive---");

  const toggle = () => {
    setIsActive(!isActive);
  };

  const [totalProfit, setTotalProfit] = useState("");
  const [todayProfit, setTodayProfit] = useState("");
  const [todayTrade, setTodayTrade] = useState("");
  const [yesterdayProfit, setYesterdayProfit] = useState("");
  const [totalBalance, setTotalBalance] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [loader_icon, setloader_icon, loader_iconref] = useState(false);
  const [MarketList, setMarketList, MarketListref] = useState([]);
  const [recentProfit, setrecentProfit, recentProfitref] = useState([]);
  const [todayInvest, setTodayInvest] = useState("");
  const [yesterdayInvest, setyesterdayInvest] = useState("");
  const [totalInvest, setTotalInvest] = useState("");
  const [totalTrade, setTotalTrade] = useState("");
  const [avgTradeprofit, setAvgTradeprofit] = useState("");
  const [monthTrade, setmonthTrade] = useState("");
  const [adminDetails, setAdminDetails, adminDetailsref] = useState("");
  const [loginCounts, setloginCounts, loginCountsref] = useState(0);

  const [todayPercent, settodayPercent] = useState("");
  const [yesterdayPercent, setyesterdayPercent] = useState("");
  const [totalPercent, settotalPercent] = useState("");
  const [todayTrades, settodayTrades] = useState(0);
  const [exchangeBalance, setexchangeBalance] = useState("");
  const [checked, setChecked] = useState(false);
  const [exchangeBalance_one, setexchangeBalance_one] = useState("");
  const [exchangeBalance_two, setexchangeBalance_two] = useState("");

  useEffect(() => {
    getTotalProfit();
    getTodayProfit();
    getYesterdayProfit();
    // getTotalBalance();
    getBotStatus();
    // getLoginHistory(1);
    // prediction("");
    // getCoinMarket();
    // recent_history();
    getexchangeBalance();
    getexchangeBalance_one();
    getexchangeBalance_two();
  }, []);

  const getBotStatus = async () => {
    try {
      var data = {
        apiUrl: apiService.getbotstatus,
      };
      setIsLoading(true);
      var resp = await getMethod(data);
      console.log(resp, "-----resp----dash status");
      setIsLoading(false);
      if (resp.status == true) {
        const details = resp.data[0].Botstatus;
        if (details == true) {
          setIsActive("ON");
        } else {
          setIsActive("OFF");
        }
      }
    } catch (error) {}
  };

  const handleChange = async (exchng, val) => {
    console.log(exchng, "exchng=-=-=-val", val);
    var obj = {
      exchange: exchng,
      status: val,
    };
    var data = {
      apiUrl: apiService.exchangestatus,
      payload: obj,
    };
    // setIsLoading(true);
    var resp = await postMethod(data);
    // setIsLoading(false);
    if (resp.status == true) {
      if (exchng === "Botstatus") {
        if (val == true) {
          setIsActive("ON");
          toast.success(" Bot Status Activated ");
        } else {
          setIsActive("OFF");
          toast.error(" Bot Status Deactivated ");
        }
      }
    }
  };

  const getTotalProfit = async () => {
    try {
      var data = {
        apiUrl: apiService.gettotalprofit,
      };
      setIsLoading(true);
      var resp = await getMethod(data);
      setIsLoading(false);
      if (resp) {
        setTotalProfit(resp.overall_profit);
        setTotalInvest(resp.overall_invest);
        setTotalTrade(resp.totaltrades);
        setAvgTradeprofit(resp.avg_profit);
        setmonthTrade(resp.monthtrades);
        var profit_percent =
          (+resp.overall_profit / +resp.overall_invest) * 100;
        // var profit_percent = (+resp.overall_profit / 800) * 100;
        profit_percent = !isNaN(profit_percent) ? profit_percent : 0;
        settotalPercent(profit_percent);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getTodayProfit = async () => {
    try {
      var data = {
        apiUrl: apiService.gettodayprofit,
      };
      setIsLoading(true);
      var resp = await getMethod(data);
      setIsLoading(false);
      if (resp) {
        setTodayProfit(resp.total_profit);
        setTodayTrade(resp.totaltrades);
        setTodayInvest(resp.total_invest);
        var profit_percent = (+resp.total_profit / +resp.total_invest) * 100;
        profit_percent = !isNaN(profit_percent) ? profit_percent : 0;
        settodayPercent(profit_percent);
        settodayTrades(resp.total_trades);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getYesterdayProfit = async () => {
    try {
      var data = {
        apiUrl: apiService.getyesterdayprofit,
      };
      setIsLoading(true);
      var resp = await getMethod(data);
      setIsLoading(false);
      if (resp) {
        setYesterdayProfit(resp.total_profit);
        setyesterdayInvest(resp.total_invest);
        var profit_percent = (+resp.total_profit / +resp.total_invest) * 100;
        profit_percent = !isNaN(profit_percent) ? profit_percent : 0;
        setyesterdayPercent(profit_percent);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getexchangeBalance = async () => {
    try {
      var data = {
        apiUrl: apiService.exchangeBalance,
      };
      setButtonLoader(true);
      var resp = await exchangegetMethod(data);
      if (resp.status == true) {
        setButtonLoader(false);
        setexchangeBalance(resp);
      }
    } catch (error) {}
  };

  const getexchangeBalance_one = async () => {
    try {
      var data = {
        apiUrl: apiService.exchangeBalance_one,
      };
      setButtonLoader(true);
      var resp = await exchangegetMethod(data);
      if (resp.status == true) {
        setButtonLoader(false);
        setexchangeBalance_one(resp);
      }
    } catch (error) {}
  };

  const getexchangeBalance_two = async () => {
    try {
      var data = {
        apiUrl: apiService.exchangeBalance_two,
      };
      setButtonLoader(true);
      var resp = await exchangegetMethod(data);
      if (resp.status == true) {
        setButtonLoader(false);
        setexchangeBalance_two(resp);
      }
    } catch (error) {}
  };

  const toggleStatus = () => {
    const newStatus = isActive === "ON" ? false : true;
    handleChange("Botstatus", newStatus);
  };

  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          duration: 4000,
        }}
      />
      <section>
        <Header />
      </section>

      <div className="container-fluid enzo-wrapper">
        <div className="row ">
          <div className="col-lg-2  pad-left-0 max-md-none bor-right max-d-none">
            <Sidebar />
          </div>
          {isLoading == true ? (
            <>
              <div className="loader-css">
                <img
                  src={require("../assets/Dual Ball@1x-1.0s-200px-200px.gif")}
                  alt="loader"
                  className="loader-gif"
                />
              </div>
            </>
          ) : (
            <div className="col-lg-10 pad-left-0">
              <div className="dashboard_content">
                <img src={LINEAR_BG} alt="linear-bg" />
              </div>
              {/* grid containers */}
              <div className="row amt-grid">
                <div className="col-lg-10">
                  {/* overall section */}
                  <div className="dashboard-top-left">
                    <h6 className="total-header-title">Overall Portfolio</h6>
                    <div className="total-flex">
                      <div>
                        <div className="total-title-wrapper mar-bottom">
                          <h6 className="total-title ">Total Invested</h6>
                          <span className="total-balance-span"></span>
                        </div>
                        <h5 className="total-amt">
                          ${" "}
                          {isNaN(totalInvest)
                            ? "0.00"
                            : parseFloat(totalInvest).toFixed(2)}
                        </h5>
                      </div>
                      <div className="total-das"></div>

                      <div>
                        {" "}
                        <div className="total-title-wrapper mar-bottom">
                          <h6 className="total-title ">Total Profit</h6>
                          <span className="total-balance-span">
                            +{parseFloat(totalPercent).toFixed(2)}%
                          </span>
                        </div>
                        <h5 className="total-amt">
                          $ {parseFloat(totalProfit).toFixed(2)}
                        </h5>
                      </div>
                      <div className="total-das"></div>

                      <div>
                        {" "}
                        <div className="total-title-wrapper mar-bottom">
                          <h6 className="total-title ">Today Invested</h6>
                          <span className="total-balance-span"></span>
                        </div>
                        <h5 className="total-amt">
                          $ {parseFloat(todayInvest).toFixed(2)}
                        </h5>
                      </div>
                      <div className="total-das"></div>

                      <div>
                        {" "}
                        <div className="total-title-wrapper mar-bottom">
                          <h6 className="total-title ">Today Profit</h6>
                          <span className="total-balance-span">
                            +{parseFloat(todayPercent).toFixed(2)}%
                          </span>
                        </div>
                        <h5 className="total-amt">
                          $ {parseFloat(todayProfit).toFixed(2)}{" "}
                        </h5>
                      </div>
                    </div>
                  </div>
                  {/* referral code and auto bot section */}
                </div>

                <div className="col-lg-2 px-lg-0">
                  {/* overall section */}
                  <div className="bot-status-top">
                    <div className="bot-status">
                      {" "}
                      {isActive === "OFF" ? (
                        <img src={require("../assets/red.png")} />
                      ) : (
                        <img src={require("../assets/green.png")} />
                      )}
                      Bot Status
                    </div>

                    <div className="Active-status">
                      <div className="activebg">
                        <button
                          className={isActive === "ON" ? "active" : ""}
                          // onClick={() => setIsActive("ON")}
                          onClick={toggleStatus}
                        >
                          ON
                        </button>
                        <button
                          className={isActive === "OFF" ? "active" : ""}
                          // onClick={() => setIsActive("OFF")}
                          onClick={toggleStatus}
                        >
                          OFF
                        </button>
                      </div>
                      <h6>
                        {isActive === "ON"
                          ? "Bot Is Active"
                          : "Bot Is Inactive"}
                      </h6>
                    </div>
                  </div>
                  {/* referral code and auto bot section */}
                </div>

                <div className="col-lg-12">
                  {/* overall section */}

                  <div className=" row trade-count-row">
                    <div className="col-lg-3 ">
                      <div
                        className="auto-bot-wrapper auto-bot-height
                    "
                      >
                        <div className="auto-top d-flex gap-3 bor-bottom align-items-center auto-inner-height">
                          <img
                            src={REFERRAL}
                            alt="ref-code"
                            className="ref-code-img"
                          />
                          <span className="ref-span">Total Trade Count</span>
                        </div>

                        <div className="auto-bottom ">
                          <h6>{totalTrade}</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 ">
                      <div
                        className="auto-bot-wrapper auto-bot-height
                    "
                      >
                        <div className="auto-top d-flex gap-3 bor-bottom align-items-center auto-inner-height">
                          <img
                            src={REFERRAL}
                            alt="ref-code"
                            className="ref-code-img"
                          />
                          <span className="ref-span">Today Trade Count</span>
                        </div>

                        <div className="auto-bottom ">
                          <h6>{todayTrades}</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 ">
                      <div
                        className="auto-bot-wrapper auto-bot-height
                    "
                      >
                        <div className="auto-top d-flex gap-3 bor-bottom align-items-center auto-inner-height">
                          <img
                            src={LAST}
                            alt="last-thirty"
                            className="ref-code-img"
                          />
                          <span className="ref-span">
                            last 30 Days Trade Count
                          </span>
                        </div>

                        <div className="auto-bottom ">
                          <h6>{totalTrade}</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 ">
                      <div
                        className="auto-bot-wrapper auto-bot-height
                    "
                      >
                        <div className="auto-top d-flex gap-3 bor-bottom align-items-center auto-inner-height">
                          <img
                            src={AVERAGE}
                            alt="average-profit"
                            className="ref-code-img"
                          />
                          <span className="ref-span">
                            Average Profit Per Trade
                          </span>
                        </div>

                        <div className="auto-bottom ">
                          <h6>
                            ${" "}
                            {avgTradeprofit == null
                              ? "0"
                              : parseFloat(avgTradeprofit).toFixed(2)}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* recent traders */}
              <div className="row table-amt-pad">
                <div className="col-lg-12">
                  {" "}
                  <div className="auto-bot-wrapper">
                    <div className="auto-top d-flex gap-3 bor-bottom align-items-center">
                      <img
                        src={LAST}
                        alt="balance-history"
                        className="ref-code-img"
                      />
                      <span className="ref-span">Balance History</span>
                    </div>

                    <div className="auto-table">
                      <div>
                        {/* {balanceHistory.map((balance) => {
                          return (
                            <BalanceHistory
                              key={balance.id}
                              balance={balance}
                            />
                          );
                        })} */}
                        {/* {buttonLoader == true ? ( */}
                        {/* <div className="loader-css-table">
                            <img
                              src={require("../assets/Dual Ball@1x-1.0s-200px-200px (2).gif")}
                              alt="loader"
                              className="loader-gif"
                            />
                          </div> */}
                        {/* ) : exchangeBalance != "" ? ( */}
                        <div className="balance-table table-container">
                          <table>
                            <thead>
                              <tr className="balance-table-row">
                                <th>Exchange</th>
                                <th className="text-nowrap balance-max-pl">
                                  Spot Balance
                                </th>
                                <th className="text-nowrap balance-max-pl">
                                  Profit Amount
                                </th>
                                <th>Date & Time</th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr>
                                <td>
                                  <div className="balance-td-row">
                                    <img
                                      src={require(`../assets/compress/binance_coion.png`)}
                                      alt="exchange-logo"
                                    />
                                    <span className="balance-span">
                                      Binance
                                    </span>
                                  </div>
                                </td>
                                <td className="balance-table-td balance-max-pl">
                                  {!isNaN(exchangeBalance.balance)
                                    ? parseFloat(
                                        exchangeBalance.balance
                                      ).toFixed(8)
                                    : 0}
                                  {/* 0 */}
                                </td>
                                <td className="balance-table-td balance-max-pl">
                                  {!isNaN(exchangeBalance.fund_balance)
                                    ? parseFloat(
                                        exchangeBalance.fund_balance
                                      ).toFixed(8)
                                    : 0}
                                  {/* 0 */}
                                </td>
                                <td className="balance-table-td balance-max-pl">
                                  {exchangeBalance.date}
                                  {/* {moment(new Date()).format("lll")} */}
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <div className="balance-td-row">
                                    <img
                                      src={require(`../assets/compress/bybit.webp`)}
                                      alt="exchange-logo"
                                    />
                                    <span className="balance-span">Bybit</span>
                                  </div>
                                </td>
                                <td className="balance-table-td balance-max-pl">
                                  {!isNaN(exchangeBalance.bybit_balance)
                                    ? parseFloat(
                                        exchangeBalance.bybit_balance
                                      ).toFixed(8)
                                    : 0}
                                  {/* 0 */}
                                </td>
                                <td className="balance-table-td balance-max-pl">
                                  {!isNaN(exchangeBalance.bybit_fund)
                                    ? parseFloat(
                                        exchangeBalance.bybit_fund
                                      ).toFixed(8)
                                    : 0}
                                  {/* 0 */}
                                </td>
                                <td className="balance-table-td balance-max-pl">
                                  {exchangeBalance.date}
                                  {/* {moment(new Date()).format("lll")} */}
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <div className="balance-td-row">
                                    <img
                                      src={require(`../assets/compress/kucoin.webp`)}
                                      alt="exchange-logo"
                                    />
                                    <span className="balance-span">Kucoin</span>
                                  </div>
                                </td>
                                <td className="balance-table-td balance-max-pl">
                                  {!isNaN(exchangeBalance.kucoin_balance)
                                    ? parseFloat(
                                        exchangeBalance.kucoin_balance
                                      ).toFixed(8)
                                    : 0}
                                  {/* 0 */}
                                </td>
                                <td className="balance-table-td balance-max-pl">
                                  {!isNaN(exchangeBalance.kucoin_fund)
                                    ? parseFloat(
                                        exchangeBalance.kucoin_fund
                                      ).toFixed(8)
                                    : 0}
                                  {/* 0 */}
                                </td>
                                <td className="balance-table-td balance-max-pl">
                                  {exchangeBalance.date}
                                  {/* {moment(new Date()).format("lll")} */}
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <div className="balance-td-row">
                                    <img
                                      src={require(`../assets/compress/gate.webp`)}
                                      alt="exchange-logo"
                                    />
                                    <span className="balance-span">Gateio</span>
                                  </div>
                                </td>
                                <td className="balance-table-td balance-max-pl">
                                  {!isNaN(exchangeBalance_one.gateio_balance)
                                    ? parseFloat(
                                        exchangeBalance_one.gateio_balance
                                      ).toFixed(8)
                                    : 0}
                                  {/* 0 */}
                                </td>
                                <td className="balance-table-td balance-max-pl">
                                  {!isNaN(exchangeBalance_one.gateio_fund)
                                    ? parseFloat(
                                        exchangeBalance_one.gateio_fund
                                      ).toFixed(8)
                                    : 0}
                                  {/* 0 */}
                                </td>
                                <td className="balance-table-td balance-max-pl">
                                  {exchangeBalance.date}
                                  {/* {moment(new Date()).format("lll")} */}
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <div className="balance-td-row">
                                    <img
                                      src={require(`../assets/compress/bitget.webp`)}
                                      alt="exchange-logo"
                                    />
                                    <span className="balance-span">Bitget</span>
                                  </div>
                                </td>
                                <td className="balance-table-td balance-max-pl">
                                  {!isNaN(exchangeBalance_one.bitget_balance)
                                    ? parseFloat(
                                        exchangeBalance_one.bitget_balance
                                      ).toFixed(8)
                                    : 0}
                                  {/* 0 */}
                                </td>
                                <td className="balance-table-td balance-max-pl">
                                  {!isNaN(exchangeBalance_one.bitget_fund)
                                    ? parseFloat(
                                        exchangeBalance_one.bitget_fund
                                      ).toFixed(8)
                                    : 0}
                                  {/* 0 */}
                                </td>
                                <td className="balance-table-td balance-max-pl">
                                  {exchangeBalance.date}
                                  {/* {moment(new Date()).format("lll")} */}
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <div className="balance-td-row">
                                    <img
                                      src={require(`../assets/compress/mexc.webp`)}
                                      alt="exchange-logo"
                                    />
                                    <span className="balance-span">Mexc</span>
                                  </div>
                                </td>
                                <td className="balance-table-td balance-max-pl">
                                  {!isNaN(exchangeBalance_one.mexc_balance)
                                    ? parseFloat(
                                        exchangeBalance_one.mexc_balance
                                      ).toFixed(8)
                                    : 0}
                                  {/* 0 */}
                                </td>
                                <td className="balance-table-td balance-max-pl">
                                  {!isNaN(exchangeBalance_one.mexc_fund)
                                    ? parseFloat(
                                        exchangeBalance_one.mexc_fund
                                      ).toFixed(8)
                                    : 0}
                                  {/* 0 */}
                                </td>
                                <td className="balance-table-td balance-max-pl">
                                  {exchangeBalance_one.date}
                                  {/* {moment(new Date()).format("lll")} */}
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <div className="balance-td-row">
                                    <img
                                      src={require(`../assets/compress/huobi.png`)}
                                      alt="exchange-logo"
                                    />
                                    <span className="balance-span">Huobi</span>
                                  </div>
                                </td>
                                <td className="balance-table-td balance-max-pl">
                                  {!isNaN(exchangeBalance_two.huobi_balance)
                                    ? parseFloat(
                                        exchangeBalance_two.huobi_balance
                                      ).toFixed(8)
                                    : 0}
                                  {/* 0 */}
                                </td>
                                <td className="balance-table-td balance-max-pl">
                                  {!isNaN(exchangeBalance_two.huobi_fund)
                                    ? parseFloat(
                                        exchangeBalance_two.huobi_fund
                                      ).toFixed(8)
                                    : 0}
                                  {/* 0 */}
                                </td>
                                <td className="balance-table-td balance-max-pl">
                                  {exchangeBalance.date}
                                  {/* {moment(new Date()).format("lll")} */}
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <div className="balance-td-row">
                                    <img
                                      src={require(`../assets/compress/bitmart.webp`)}
                                      alt="exchange-logo"
                                    />
                                    <span className="balance-span">
                                      Bitmart
                                    </span>
                                  </div>
                                </td>
                                <td className="balance-table-td balance-max-pl">
                                  {!isNaN(exchangeBalance_two.bitmart_balance)
                                    ? parseFloat(
                                        exchangeBalance_two.bitmart_balance
                                      ).toFixed(8)
                                    : 0}
                                  {/* 0 */}
                                </td>
                                <td className="balance-table-td balance-max-pl">
                                  {!isNaN(exchangeBalance_two.bitmart_fund)
                                    ? parseFloat(
                                        exchangeBalance_two.bitmart_fund
                                      ).toFixed(8)
                                    : 0}
                                  {/* 0 */}
                                </td>
                                <td className="balance-table-td balance-max-pl">
                                  {exchangeBalance.date}
                                  {/* {moment(new Date()).format("lll")} */}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        {/* // ) : (
                        //   ""
                        // )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;

/** */
