import React, { useEffect } from "react";
import { useState } from "react";
import LOGO from "../assets/compress/enzo-logo2.png";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
// import LogoutIcon from "@mui/icons-material/Logout";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  ListItem,
} from "@material-ui/core";
import apiService from "../core/sevice/detail";
import { getMethod, postMethod } from "../core/sevice/common.api";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  appBarItems: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileMenuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  drawerIcon: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  drawer: {
    width: 300,
  },
}));

const Header = () => {
  // const [open, setOpen] = useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const classes = useStyles();

  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(true);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuOpen(false);
  };

  useEffect(() => {
    verifyToken();
    loginCheck();
  }, []);

  const verifyToken = async () => {
    console.log("-------Verification calls---------");
    const token = sessionStorage.getItem("user_token");

    if (token) {
      try {
        const datas = {
          apiUrl: apiService.verifyToken, // Endpoint for token verification
          payload: { token },
        };

        const response = await postMethod(datas);

        if (response.status === 401 || response.message === "TokenExpired") {
          handleLogout();
        } else {
          console.log("Token is valid");
        }
      } catch (error) {
        console.log("Error in token verification", error);
        handleLogout(); // Handle error in verification process, assuming token might be invalid
      }
    }
  };

  const navigate = useNavigate();

  const handleLogout = () => {
    // Display error toast
    toast.error("Session expired. Please log in again.");

    // Clear sessionStorage and localStorage
    sessionStorage.clear();
    localStorage.clear();

    // Redirect to the login page
    navigate("/");
  };

  const [loginStatus,setLoginStatus] = useState(false);

  const loginCheck = async() => {
    let tokenVerify = sessionStorage.getItem("user_token");
    if(!tokenVerify || tokenVerify == "" ){
      setLoginStatus(false);
    }else {
      setLoginStatus(true);
    }
  }

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/");
}

  return (
    <header className="">
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          duration: 4000,
        }}
      />
      <div className="scrolled">
        <div className="header_new_mega">
          <div className={`${classes.root} `}>
            <AppBar position="static">
              <Toolbar className="container-fluid enzo-wrapper">
                <Typography variant="h6" className={classes.title}>
                  <div className=" d-flex">
                    <nav className="container-fluid primary-navigation">
                      <div className="logo-container">
                        <Link to="/dashboard">
                          <img src={LOGO} alt="logo" />
                        </Link>
                      </div>
                    </nav>
                  </div>
                </Typography>

                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  className={` m-0 p-0 ${classes.mobileMenuButton} ${classes.drawerIcon}`}
                >
                  <MenuIcon onClick={handleMobileMenuOpen} />
                </IconButton>

                <div
                  className={`menu_new_typr ${classes.appBarItems} max-d-none`}
                >
                  {/* <Link to="">
                    <button className="primary-btn d-flex align-items-center gap-2">
                      Logout
                      <span>
                        <img
                          src={require("../assets/compress/logout.webp")}
                          alt="logout"
                          className="logout-icon"
                        />
                      </span>
                    </button>
                  </Link> */}
                  {loginStatus == false ? (
                    <Link to="/login">
                      <button className="primary-btn">Sign in</button>
                    </Link>
                  ): (
                    <button className="primary-btn" onClick={logout}>Logout</button>
                  )}
                </div>
              </Toolbar>
            </AppBar>
            <Drawer
              anchor="left"
              open={mobileMenuOpen}
              className={`${classes.drawer}`}
              onClose={() => handleMobileMenuClose(false)}
            >
              <div className={`${classes.mobileMenu}`}>
                <div className="logo_new_sectio mobile_menu_icon ">
                  <Link to="/dashboard">
                    <img src={LOGO} alt="logo" className="mobile-logo" />
                  </Link>
                </div>

                <div className="menu_statis">
                  <ListItem button className="drawa">
                    <Link to="/dashboard">Dashboard</Link>
                  </ListItem>

                  <ListItem button className="drawa">
                    <Link to="/profit">Profit</Link>
                  </ListItem>

                  <ListItem button className="drawa">
                    <Link to="/opportunity">Opportunities</Link>
                  </ListItem>

                  <ListItem button className="drawa ">
                    <Link to="/connect-exchange">Connect Exchange</Link>
                  </ListItem>

                  <ListItem button className="drawa ">
                    <Link to="/strategy">Strategies</Link>
                  </ListItem>

                  <ListItem button className="drawa ">
                    <Link to="/trade-history">Trade History</Link>
                  </ListItem>

                  <ListItem button className="drawa ">
                    <Link to="/login-history">Login History</Link>
                  </ListItem>

                  <ListItem button className="drawa ">
                    <Link to="/login-history">Logout</Link>
                  </ListItem>
                </div>
              </div>
            </Drawer>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
