import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Opportunities from "./pages/Opportunities";
import Earnings from "./pages/Profit";
import Register from "./pages/Register";
import ConnectExchange from "./pages/ConnectExchange";
import TradeHistory from "./pages/TradeHistory";
import Login from "./pages/Login";
import LoginHistory from "./pages/LoginHistory";
import ForgotPassword from "./pages/ForgotPassword";
import { removeAuthToken } from "./core/lib/localStorage";
import Strategy from "./pages/Strategy";

function App() {
  function RequireAuth({ children }) {
    var data = sessionStorage.getItem("user_token");
    return data ? children : removeAuthToken();
  }

  return (
    <>
      {/* <Header /> */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route
            path="/opportunity"
            element={
              <RequireAuth>
                <Opportunities />
              </RequireAuth>
            }
          />
          <Route
            path="/profit"
            element={
              <RequireAuth>
                <Earnings />
              </RequireAuth>
            }
          />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/connect-exchange"
            element={
              <RequireAuth>
                <ConnectExchange />
              </RequireAuth>
            }
          />
          <Route
            path="/strategy"
            element={
              <RequireAuth>
                <Strategy />
              </RequireAuth>
            }
          />
          <Route
            path="/trade-history"
            element={
              <RequireAuth>
                <TradeHistory />
              </RequireAuth>
            }
          />
          <Route
            path="/login-history"
            element={
              <RequireAuth>
                <LoginHistory />
              </RequireAuth>
            }
          />
          <Route path="/forgot" element={<ForgotPassword />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
