import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";
import LINEAR_BG from "../assets/compress/linear-bg-primary.webp";
import LAST from "../assets/compress/profit-icon.webp";
import ProfitTable from "./ProfitTable";
import apiService from "../core/sevice/detail";
import { getMethod, postMethod } from "../core/sevice/common.api";
import moment from "moment";
import { socket } from "../core/context/socket";
import toast, { Toaster } from "react-hot-toast";
import ReactPaginate from "react-paginate";
import * as XLSX from "xlsx";

const Earnings = () => {
  const [isLoading, setIsLoading, isLoadingref] = useState(false);
  const [TotalPages, setTotalPages, TotalPagesref] = useState(0);
  const [search, setsearch, searchref] = useState("");

  const [search_trade, setsearch_trade, search_traderef] = useState("");
  const [tradeOrders, settradeOrders, tradeOrdersref] = useState([]);
  const [TotalPages_trade, setTotalPages_trade, TotalPages_traderef] =
    useState(0);

  const [startDate, setstartDate, startDateref] = useState("");

  const [endDate, setendDate, endDateref] = useState("");
  const [alltradeHistory, setalltradeHistory, alltradeHistoryref] = useState(
    []
  );

  useEffect(() => {
    getTradeHistory(1);

    socket.on("GetProfitOrders_user", async (response) => {
      console.log("GetProfitOrders_user", response);
      if (response != null) {
        var profit_data = [];
        if (response.data.length > 0) {
          var resp_data = response.data;
          for (var i = 0; i < resp_data.length; i++) {
            var buy_total =
              +resp_data[i].buy_price * +resp_data[i].buy_quantity;
            var sell_total =
              +resp_data[i].sell_price * +resp_data[i].sell_quantity;
            var profit_percentage =
              (+resp_data[i].profit_amount / buy_total) * 100;
            var obj = {
              pair: resp_data[i].primary + "/" + resp_data[i].secondary,
              amount: parseFloat(resp_data[i].buy_quantity).toFixed(2),
              entry_price: parseFloat(resp_data[i].buy_average_price).toFixed(
                2
              ),
              exit_price: parseFloat(resp_data[i].sell_price).toFixed(2),
              entry_total: parseFloat(buy_total).toFixed(2),
              exit_total: parseFloat(sell_total).toFixed(2),
              profit_amount: parseFloat(+resp_data[i].profit_amount).toFixed(2),
              profit_percentage: parseFloat(+profit_percentage).toFixed(2),
              buy_exchange: resp_data[i].buy_exchange,
              sell_exchange: resp_data[i].sell_exchange,
              strategy: resp_data[i].strategy,
              date: moment(resp_data[i].createdAt).format("DD/MM/YYYY"),
            };

            profit_data.push(obj);
          }
        }
        settradeOrders(profit_data);
      }
    });
  }, [0]);

  const socket_trade = async () => {
    socket.on("GetProfitOrders_user", async (response) => {
      console.log("GetProfitOrders_user", response);
      if (response != null) {
        var profit_data = [];
        if (response.data.length > 0) {
          var resp_data = response.data;
          for (var i = 0; i < resp_data.length; i++) {
            var buy_total =
              +resp_data[i].buy_price * +resp_data[i].buy_quantity;
            var sell_total =
              +resp_data[i].sell_price * +resp_data[i].sell_quantity;
            var profit_percentage =
              (+resp_data[i].profit_amount / buy_total) * 100;
            var obj = {
              pair: resp_data[i].primary + "/" + resp_data[i].secondary,
              amount: parseFloat(resp_data[i].buy_quantity).toFixed(2),
              entry_price: parseFloat(resp_data[i].buy_price).toFixed(2),
              exit_price: parseFloat(resp_data[i].sell_price).toFixed(2),
              entry_total: parseFloat(buy_total).toFixed(2),
              exit_total: parseFloat(sell_total).toFixed(2),
              profit_amount: parseFloat(+resp_data[i].profit_amount).toFixed(2),
              profit_percentage: parseFloat(+profit_percentage).toFixed(2),
              buy_exchange: resp_data[i].buy_exchange,
              sell_exchange: resp_data[i].sell_exchange,
              strategy: resp_data[i].strategy,
              date: moment(resp_data[i].createdAt).format("DD/MM/YYYY"),
            };

            profit_data.push(obj);
          }
        }
        settradeOrders(profit_data);
      }
    });
  };
  const setfilterDate = async (val, type) => {
    if (type == "start") {
      setstartDate(val);
    } else {
      setendDate(val);
    }
    console.log("date value", val);
    if (val != "") {
      getTradeHistory_filter(1);
    } else {
      getTradeHistory(1);
      socket_trade();
    }
  };

  const getTradeHistory = async (currentPage) => {
    let count = currentPage == undefined ? 1 : currentPage;
    try {
      var payload = {
        perPage: 10,
        page: count,
        search: search_traderef.current,
        startdate: startDateref.current,
        enddate: endDateref.current,
      };
      var data = {
        apiUrl: apiService.getProfit,
        payload: payload,
      };
      setIsLoading(true);
      var resp = await postMethod(data);
      console.log("trade history resp", resp);
      var order_resp = resp.data;
      setIsLoading(false);
      if (order_resp.status == true) {
        var profit_data = [];
        if (order_resp.result.length > 0) {
          var resp_data = order_resp.result;
          for (var i = 0; i < resp_data.length; i++) {
            var buy_total =
              +resp_data[i].buy_price * +resp_data[i].buy_quantity;
            var sell_total =
              +resp_data[i].sell_price * +resp_data[i].sell_quantity;
            var profit_percentage =
              (+resp_data[i].profit_amount / buy_total) * 100;
            var obj = {
              pair: resp_data[i].primary + "/" + resp_data[i].secondary,
              amount: parseFloat(resp_data[i].buy_quantity).toFixed(2),
              entry_price: parseFloat(resp_data[i].buy_average_price).toFixed(
                2
              ),
              exit_price: parseFloat(resp_data[i].sell_price).toFixed(2),
              entry_total: parseFloat(buy_total).toFixed(2),
              exit_total: parseFloat(sell_total).toFixed(2),
              profit_amount: parseFloat(+resp_data[i].profit_amount).toFixed(2),
              profit_percentage: parseFloat(+profit_percentage).toFixed(2),
              buy_exchange: resp_data[i].buy_exchange,
              sell_exchange: resp_data[i].sell_exchange,
              strategy: resp_data[i].strategy,
              date: moment(resp_data[i].createdAt).format("lll"),
            };

            profit_data.push(obj);
          }
        }
        settradeOrders(profit_data);
        setTotalPages_trade(order_resp.pages);
      }
      //  setTimeout(async () => {
      //     getTradeHistory_socket(currentPage);
      //   },1000);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handlePageClick_trade = async (data) => {
    console.log(data.selected);
    let currentPage = data.selected + 1;
    getTradeHistory(currentPage);
  };

  const getTradeHistory_filter = async (currentPage) => {
    socket.off("GetProfitOrders_user");
    let count = currentPage == undefined ? 1 : currentPage;
    try {
      var payload = {
        perPage: 10,
        page: count,
        search: search_traderef.current,
        startdate: startDateref.current,
        enddate: endDateref.current,
      };
      var data = {
        apiUrl: apiService.getProfit,
        payload: payload,
      };
      setIsLoading(true);
      var resp = await postMethod(data);
      console.log("trade history resp", resp);
      var order_resp = resp.data;
      setIsLoading(false);
      if (order_resp.status == true) {
        var profit_data = [];
        if (order_resp.result.length > 0) {
          var resp_data = order_resp.result;
          for (var i = 0; i < resp_data.length; i++) {
            var buy_total =
              +resp_data[i].buy_price * +resp_data[i].buy_quantity;
            var sell_total =
              +resp_data[i].sell_price * +resp_data[i].sell_quantity;
            var profit_percentage =
              (+resp_data[i].profit_amount / buy_total) * 100;
            var obj = {
              pair: resp_data[i].primary + "/" + resp_data[i].secondary,
              amount: parseFloat(resp_data[i].buy_quantity).toFixed(2),
              entry_price: parseFloat(resp_data[i].buy_average_price).toFixed(
                2
              ),
              exit_price: parseFloat(resp_data[i].sell_price).toFixed(2),
              entry_total: parseFloat(buy_total).toFixed(2),
              exit_total: parseFloat(sell_total).toFixed(2),
              profit_amount: parseFloat(+resp_data[i].profit_amount).toFixed(2),
              profit_percentage: parseFloat(+profit_percentage).toFixed(2),
              buy_exchange: resp_data[i].buy_exchange,
              sell_exchange: resp_data[i].sell_exchange,
              strategy: resp_data[i].strategy,
              date: moment(resp_data[i].createdAt).format("lll"),
            };

            profit_data.push(obj);
          }
        }
        settradeOrders(profit_data);
        setTotalPages_trade(order_resp.pages);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const handleChange = (e) => {
    try {
      const sanitizedValue = e.target.value.replace(/\s/g, "");
      console.log(sanitizedValue, "-=-sanitizedValue=-=");
      setsearch_trade(sanitizedValue);
      // setsearch(e.target.value);
      searchTradeOrders();
    } catch (error) {}
  };
  const searchTradeOrders = async () => {
    console.log("search_traderef", search_traderef.current);
    if (search_traderef.current != "") {
      getTradeHistory_filter(1);
    } else {
      getTradeHistory(1);
      socket_trade();
    }
  };

  const getTradeHistory_socket = async (currentPage) => {
    let count = currentPage == undefined ? 1 : currentPage;
    try {
      var payload = {
        perPage: 10,
        page: count,
        search: search_traderef.current,
        startdate: startDateref.current,
        enddate: endDateref.current,
      };
      console.log("socket payload", payload);
      socket.emit("GetProfitOrders", payload);
    } catch (error) {}
  };

  const downloadData = async () => {
    try {
      var data = {
        apiUrl: apiService.getalltradehistory,
      };
      var resp = await getMethod(data);
      console.log(resp, "resprespresprespresp");
      if (resp.status == true) {
        setalltradeHistory(resp.data);
        const fieldsToExport = [
          "pair",
          "buy_exchange",
          "sell_exchange",
          "profit_amount",
          "strategy",
          "updatedAt",
        ];
        console.log("Admin Details:", alltradeHistoryref.current);
        const filteredData = alltradeHistoryref.current.map((item) => {
          const filteredItem = {};
          fieldsToExport.forEach((field) => {
            filteredItem[field] = item[field];
          });
          console.log("Filtered Item:", filteredItem);
          return filteredItem;
        });
        console.log("Filtered Data:", filteredData);
        const worksheet = XLSX.utils.json_to_sheet(filteredData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "TradeHistory.xlsx");

        toast.success("File Downloaded");
      }
    } catch (error) {}
  };

  return (
    <>
      <section>
        <Header />
      </section>

      <div className="container-fluid enzo-wrapper">
        <div className="row ">
          <div className="col-lg-2 pad-left-0 max-md-none bor-right max-d-none">
            <Sidebar />
          </div>

          <div className="col-lg-10 pad-left-0 mar-bot">
            <div className="dashboard_content">
              <img src={LINEAR_BG} alt="linear-bg" />
            </div>
            <div className="amt-grid">
              <div className="auto-bot-wrapper profit-mar-top">
                <div className="auto-top profit-top bor-bottom ">
                  <div className="d-flex gap-3 align-items-center">
                    <img
                      src={LAST}
                      alt="balance-history"
                      className="ref-profit-img"
                    />
                    <span className="ref-profit">Profit</span>
                  </div>
                  <div className="profit-right-search">
                    <div className="profit-filter">
                      <div className="order-from">
                        <span className="order-from-fil">From </span>
                        <input
                          type="date"
                          placeholder="from"
                          className="order-from-inp"
                          onChange={(e) =>
                            setfilterDate(e.target.value, "start")
                          }
                        />
                      </div>
                      <div className="order-from">
                        <span className="order-from-fil">To </span>
                        <input
                          type="date"
                          placeholder="to"
                          className="order-from-inp"
                          onChange={(e) => setfilterDate(e.target.value, "end")}
                        />
                      </div>
                    </div>
                    <div className="profit-search-wrapper">
                      <input
                        type="text"
                        placeholder="Search"
                        // onChange={(e) => setsearch_trade(e.target.value)}
                        onChange={handleChange}
                      />
                      <span>
                        <i
                          class="fa-solid fa-magnifying-glass"
                          onClick={searchTradeOrders}
                        ></i>
                      </span>
                    </div>
                    <div className="profit-download-wrapper">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6"
                        onClick={downloadData}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                        />
                      </svg>
                    </div>
                  </div>
                </div>

                <div className="auto-table">
                  {/* <ProfitTable /> */}

                  <div className="table-container">
                    <table>
                      <thead>
                        <tr className="profit-thead-row">
                          <th>Pair</th>
                          <th>Quantity</th>
                          <th>Entry Price</th>
                          <th>Exit Price</th>
                          <th>
                            Profit<div>(USDT)</div>
                          </th>
                          <th>
                            Profit<div>(%)</div>
                          </th>
                          <th>Buy Exchange</th>
                          <th>Sell Exchange</th>
                          <th>Strategy</th>
                          <th>Created At</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoadingref.current == true ? (
                          <>
                            <tr className="text-center">
                              <td colSpan={10}>
                                <div className="loader-css-table">
                                  <img
                                    src={require("../assets/Dual Ball@1x-1.0s-200px-200px (2).gif")}
                                    alt="loader"
                                    className="loader-gif"
                                  />
                                </div>
                              </td>
                            </tr>
                          </>
                        ) : (
                          <>
                            {tradeOrdersref.current.length > 0 ? (
                              tradeOrdersref.current.map((element, index) => {
                                var strategy =
                                  element.strategy == "intra" ||
                                  element.strategy == "dca"
                                    ? "Intra"
                                    : element.strategy;
                                return (
                                  <tr className="profit-tbody-row">
                                    <td>{element.pair}</td>
                                    <td>{element.amount}</td>
                                    <td>
                                      {parseFloat(element.entry_price).toFixed(
                                        2
                                      )}
                                    </td>
                                    <td>
                                      {parseFloat(element.exit_price).toFixed(
                                        2
                                      )}
                                    </td>
                                    <td>{element.profit_amount}</td>
                                    <td>{element.profit_percentage}</td>
                                    <td>{element.buy_exchange}</td>
                                    <td>{element.sell_exchange}</td>
                                    <td>{strategy}</td>
                                    <td>{element.date}</td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={10} className="text-center py-5">
                                  <div className="empty_data">
                                    <div className="empty_data_img">
                                      <img
                                        src={require("../assets/No-data.webp")}
                                        width="100px"
                                      />
                                    </div>
                                    <div className="no_records_text">
                                      No Records Found
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                        {tradeOrdersref.current.length > 0 ? (
                          <tr className="text-center text-white pagi_Btm_borno">
                            <td colSpan="12">
                              <div className="paginationcss">
                                <ReactPaginate
                                  previousLabel={"<"}
                                  nextLabel={">"}
                                  breakLabel={"**"}
                                  pageCount={TotalPages_traderef.current}
                                  marginPagesDisplayed={1}
                                  pageRangeDisplayed={0}
                                  onPageChange={handlePageClick_trade}
                                  containerClassName={
                                    "pagination pagination-md justify-content-center custom-pagination"
                                  }
                                  pageClassName={"page-item"}
                                  pageLinkClassName={"page-link"}
                                  previousClassName={"page-item"}
                                  previousLinkClassName={"page-link"}
                                  nextClassName={"page-item"}
                                  nextLinkClassName={"page-link"}
                                  breakClassName={"page-item"}
                                  breakLinkClassName={"page-link"}
                                  activeClassName={"active"}
                                />
                              </div>
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Earnings;
