const service = {
    signin: "api/admin/admin_login",
    gettotalprofit : "api/admin/get_month_profit",
    gettodayprofit : "api/admin/get_today_profit",
    getyesterdayprofit : "api/admin/get_yesterday_profit",
    gettotalbalance : "api/admin/admin_totalbalance",
    botstatus: "api/admin/bot_status",
    exchangestatus: "api/admin/exchange_status",
    getbotstatus: "api/admin/get_bot_status",
    getcoinmarket: "api/admin/get_market_details",
    apikeycreate: "api/admin/createKeymange",
    getkeys: "api/admin/getkeymanager",
    getOpenOrders: "api/profit/open_orders",
    getTradeHistory: "api/profit/trade_history",
    getProfit: "api/profit/bot_profit",
    getchartData: "api/profit/chart_data",
    get_admin_balance: "api/admin/admin_balance",
    get_wallet_list: "api/admin/fund_wallet_list",
    get_admin_transactions: "api/admin/adminwallet_transactions_deposit",
    getmarkets: "api/admin/getmarkets",
    recent_profit: "api/admin/recent_profit",
    adminchangepswdlink : "api/admin/adminchangepswdlink",
    adminverifyForgotpasslink : "api/admin/verifyForgotpasslink",
    adminresetpassword : "api/admin/forgotpassword",
    get_recent_deposit:"api/admin/get_recent_deposit",
    getadminlogin:"api/admin/getadminLogindetails",
    getexchangestatus:"api/admin/getexchangestatus",
    getalladminlogin:"api/admin/getalladminlogin",
    getalltradehistory:"api/profit/getalltradehistory",
    exchangeBalance:"api/profit/exchange_balance",
    getInvestment:"api/admin/get_investment",
    updateInvestment:"api/admin/set_investment",
    verifyToken: "api/admin/verifyToken",
    exchangeBalance_one:"api/profit/exchange_balance_one",
    exchangeBalance_two:"api/profit/exchange_balance_two",
}

export default service;