import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import DASHBOARD from "../assets/compress/dashboard-icon.webp";
import OPPORTUNITY from "../assets/compress/opportunity-icon.webp";
import EARNINGS from "../assets/compress/earnings-icon.webp";
import CONNECT from "../assets/compress/connect-exchange.webp";
import HISTORY from "../assets/compress/history.webp";

const Sidebar = () => {
  const [activeLink, setActiveLink] = useState("");
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname.split("/").pop();
    setActiveLink(path);

    // Open the dropdown if the user is on either login or trade history
    if (path === "trade-history" || path === "login-history") {
      setIsHistoryOpen(true);
    } else {
      setIsHistoryOpen(false); // Close the dropdown on other pages
    }
  }, [location]);

  const isActive = (path) => {
    return activeLink === path ? "active-link" : "";
  };

  // Determines if the parent "History" link should be active
  const isHistoryActive = () => {
    return activeLink === "trade-history" || activeLink === "login-history";
  };

  // Toggle the History dropdown manually
  const toggleHistoryDropdown = (e) => {
    e.preventDefault(); // Prevent default link behavior (no navigation)
    setIsHistoryOpen((prevState) => !prevState);
  };

  return (
    <div>
      <div className="col-lg-2">
        <div className="dashboard_pages_list">
          <Link
            to="/dashboard"
            className={`dashboard_title ${isActive("dashboard")}`}
          >
            <img src={DASHBOARD} alt="Dashboard" />
            <h3>Dashboard</h3>
          </Link>

          <Link
            to="/profit"
            className={`dashboard_title ${isActive("profit")}`}
          >
            <img src={EARNINGS} alt="profit" />
            <h3>Profit</h3>
          </Link>

          <Link
            to="/opportunity"
            className={`dashboard_title ${isActive("opportunity")}`}
          >
            <img src={OPPORTUNITY} alt="opportunity" />
            <h3>Opportunities</h3>
          </Link>

          <Link
            to="/connect-exchange"
            className={`dashboard_title ${isActive("connect-exchange")}`}
          >
            <img
              src={CONNECT}
              alt="connect-exchange"
              className="connect-exchange-img"
            />
            <h3>Connect Exchange</h3>
          </Link>

          <Link
            to="/strategy"
            className={`dashboard_title ${isActive("strategy")}`}
          >
            <img
              src={require("../assets/compress/strategy-icon.webp")}
              alt="connect-exchange"
              className="connect-exchange-img"
            />
            <h3>Strategies</h3>
          </Link>

          {/* History Section */}
          <a
            href="#"
            onClick={toggleHistoryDropdown}
            className={`dashboard_title history-wrapper ${
              isHistoryActive() ? "active-link" : ""
            }`}
          >
            <div className="history-top">
              <img src={HISTORY} alt="trade-history" />
              <h3>History</h3>
            </div>
          </a>

          {isHistoryOpen && (
            <div className="history-pages">
              <Link
                to="/login-history"
                className={activeLink == "login-history" ? "active-link" : ""}
              >
                <h3>Login History</h3>
                <span>
                  <i className="fa-solid fa-angle-right"></i>
                </span>
              </Link>

              <Link
                to="/trade-history"
                className={activeLink == "trade-history" ? "active-link" : ""}
              >
                <h3>Trade History</h3>
                <span>
                  <i className="fa-solid fa-angle-right"></i>
                </span>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
