import React, { useEffect } from "react";
import useState from "react-usestateref";
import apiService from "../core/sevice/detail";
import { postMethod } from "../core/sevice/common.api";
import toast, { Toaster } from "react-hot-toast";
import LINEAR_BG from "../assets/compress/linear-bg-primary.webp";
import { centralizeExchange, decentralizeExchange } from "../utils/mockData";
import LAST from "../assets/compress/decentralized.webp";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Centralized from "./Centralized";
import Decentralized from "./Decentralized";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid rgba(45, 45, 45, 0.5)",
  boxShadow: 24,
  p: 4,
};

const ConnectExchange = () => {
  const initialFormValue = {
    exchange: "",
  };

  const [apikeyValidate, setapikeyValidate, apikeyValidateref] =
    useState(false);
  const [secretkeyValidate, setsecretkeyValidate, secretkeyValidateref] =
    useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [buttonLoader, setbuttonLoader] = useState(false);

  const [formValues, setFormValues, formValuesref] = useState([]);

  const { exchange } = formValue;

  let addFormFields = () => {
    setFormValues([
      ...formValuesref.current,
      { apikey: "", secretkey: "", passphrase: "" },
    ]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValuesref.current];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  let handleChange = (i, e) => {
    let newFormValues = [...formValuesref.current];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
    console.log("formValues", formValuesref.current);
    validate(i, formValuesref.current);
  };

  const setExchange = async (e) => {
    // e.preventDefault();
    console.log("value", e.target.getAttribute("data-value"));
    var exchange_value = e.target.getAttribute("data-value");
    formValue.exchange = e.target.getAttribute("data-value");
    if (exchange_value != null) {
      var data = {
        apiUrl: apiService.getkeys,
        payload: { exchange: exchange_value },
      };
      var resp = await postMethod(data);
      console.log("key response==", resp);
      if (resp.status == true) {
        setFormValues(resp.data);
        console.log("formValue==", formValuesref.current);
      } else {
        console.log("Call empty value");
        setFormValues([{ apikey: "", secretkey: "", passphrase: "" }]);
        console.log("Call formvalues", formValuesref.current);
        formValue.exchange = e.target.getAttribute("data-value");
      }
    }
  };

  const get_exchange = async (exchange) => {
    if (exchange != null) {
      var data = {
        apiUrl: apiService.getkeys,
        payload: { exchange: exchange },
      };
      var resp = await postMethod(data);
      console.log("key response==", resp);
      formValue.exchange = exchange;
      if (resp.status == true) {
        setFormValues(resp.data);
        console.log("formValue==", formValuesref.current);
      } else {
        setFormValues([{ apikey: "", secretkey: "" }]);
        formValue.exchange = exchange;
      }
    }
  };

  const validate = async (i, value) => {
    var values = value[i];
    const errors = {};
    // if (!values.exchange) {
    //   errors.exchange = "Exchange is a required field !";
    //   setexchangeValidate(true);
    // } else {
    //   setexchangeValidate(false);
    // }
    console.log("call validation");
    if (
      values.apikey == "" ||
      values.apikey == null ||
      values.apikey == undefined
    ) {
      setapikeyValidate(true);
      errors.apikey = "Api key is a required field !";
      errors.index = i;
    } else if (values.apikey == "********") {
      console.log("call here");
      setapikeyValidate(true);
      errors.apikey = "Api key is a required field !";
      errors.index = i;
    } else {
      setapikeyValidate(false);
    }

    if (
      values.secretkey == "" ||
      values.secretkey == null ||
      values.secretkey == undefined
    ) {
      setsecretkeyValidate(true);
      errors.secretkey = "Secret key is a required field !";
      errors.index = i;
    } else if (values.secretkey == "********") {
      setsecretkeyValidate(true);
      errors.secretkey = "Secret key is a required field !";
      errors.index = i;
    } else {
      setsecretkeyValidate(false);
    }

    setvalidationnErr(errors);
    return errors;
  };

  const formSubmit = async () => {
    if (formValuesref.current.length > 0) {
      for (var i = 0; i < formValuesref.current.length; i++) {
        validate(i, formValuesref.current);
      }
    }
    if (
      apikeyValidateref.current === false &&
      secretkeyValidateref.current === false
    ) {
      console.log(formValuesref.current);
      var payload = {
        exchange: formValue.exchange,
        formvalues: formValuesref.current,
      };
      var data = {
        apiUrl: apiService.apikeycreate,
        payload: payload,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      if (resp.status == true) {
        toast.success(resp.message);
        get_exchange(formValue.exchange);
        // console.log(resp.message, "[--=-=resp---resp.message-");
      } else {
        setbuttonLoader(false);
        toast.error(resp.message);
      }
    }
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isDecentraOpen, setIsDecentraOpen] = useState(false);
  const handleDecentraOpen = () => setIsDecentraOpen(true);
  const handleDecentraClose = () => setIsDecentraOpen(false);

  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          duration: 4000,
        }}
      />
      <section>
        <Header />
      </section>

      <div className="container-fluid enzo-wrapper">
        <div className="row ">
          <div className="col-lg-2 pad-left-0 max-md-none bor-right max-d-none">
            <Sidebar />
          </div>

          <div className="col-lg-10 pad-left-0 mar-bot">
            <div className="dashboard_content">
              <img src={LINEAR_BG} alt="linear-bg" />
            </div>
            <div className="amt-grid">
              <div className="auto-bot-wrapper profit-mar-top">
                <div className="auto-top profit-top bor-bottom ">
                  <div className="d-flex gap-3 align-items-center">
                    <img
                      src={LAST}
                      alt="balance-history"
                      className="ref-profit-img"
                    />
                    <span className="ref-profit">Centralized Exchange</span>
                  </div>
                </div>

                <div className="centralized-new-wrapper">
                  {/* {centralizeExchange.map((data) => {
                    return <Centralized key={data.id} data={data} />;
                  })} */}
                  <div className="centralized-new-card">
                    <img
                      src={require(`../assets/compress/binance.webp`)}
                      alt=""
                    />
                    <div>
                      <h6>Binance</h6>
                    </div>{" "}
                    <div
                      className="clickable-overlay"
                      data-value="BINANCE"
                      onClick={(e) => {
                        setExchange(e); // Set the exchange
                        handleOpen(); // Open the modal
                      }}
                    ></div>
                  </div>
                  <div className="centralized-new-card">
                    <img
                      src={require(`../assets/compress/bitget.webp`)}
                      alt=""
                    />
                    <div>
                      <h6>Bitget</h6>
                    </div>{" "}
                    <div
                      className="clickable-overlay"
                      data-value="BITGET"
                      onClick={(e) => {
                        setExchange(e); // Set the exchange
                        handleOpen(); // Open the modal
                      }}
                    ></div>
                  </div>
                  <div className="centralized-new-card">
                    <img
                      src={require(`../assets/compress/bitmart.webp`)}
                      alt=""
                    />
                    <div>
                      <h6>Bitmart</h6>
                    </div>{" "}
                    <div
                      className="clickable-overlay"
                      data-value="BITMART"
                      onClick={(e) => {
                        setExchange(e); // Set the exchange
                        handleOpen(); // Open the modal
                      }}
                    ></div>
                  </div>
                  <div className="centralized-new-card">
                    <img src={require(`../assets/Bigone.png`)} alt="" />
                    <div>
                      <h6>Bigone</h6>
                    </div>{" "}
                    <div
                      className="clickable-overlay"
                      data-value="BIGONE"
                      onClick={(e) => {
                        setExchange(e); // Set the exchange
                        handleOpen(); // Open the modal
                      }}
                    ></div>
                  </div>
                  <div className="centralized-new-card">
                    <img src={require(`../assets/Bingx.png`)} alt="" />
                    <div>
                      <h6>Bingx</h6>
                    </div>{" "}
                    <div
                      className="clickable-overlay"
                      data-value="BINGX"
                      onClick={(e) => {
                        setExchange(e); // Set the exchange
                        handleOpen(); // Open the modal
                      }}
                    ></div>
                  </div>
                  <div className="centralized-new-card">
                    <img
                      src={require(`../assets/compress/bybit.webp`)}
                      alt=""
                    />
                    <div>
                      <h6>Bybit</h6>
                    </div>{" "}
                    <div
                      className="clickable-overlay"
                      data-value="BYBIT"
                      onClick={(e) => {
                        setExchange(e); // Set the exchange
                        handleOpen(); // Open the modal
                      }}
                    ></div>
                  </div>
                  <div className="centralized-new-card">
                    <img src={require(`../assets/Digitinex.png`)} alt="" />
                    <div>
                      <h6>Digifinex</h6>
                    </div>{" "}
                    <div
                      className="clickable-overlay"
                      data-value="DIGIFINEX"
                      onClick={(e) => {
                        setExchange(e); // Set the exchange
                        handleOpen(); // Open the modal
                      }}
                    ></div>
                  </div>
                  <div className="centralized-new-card">
                    <img src={require(`../assets/compress/gate.webp`)} alt="" />
                    <div>
                      <h6>Gate.io</h6>
                    </div>{" "}
                    <div
                      className="clickable-overlay"
                      data-value="GATE.IO"
                      onClick={(e) => {
                        setExchange(e); // Set the exchange
                        handleOpen(); // Open the modal
                      }}
                    ></div>
                  </div>
                  <div className="centralized-new-card">
                    <img src={require(`../assets/Haubi.png`)} alt="" />
                    <div>
                      <h6>Huobi</h6>
                    </div>{" "}
                    <div
                      className="clickable-overlay"
                      data-value="HUOBI"
                      onClick={(e) => {
                        setExchange(e); // Set the exchange
                        handleOpen(); // Open the modal
                      }}
                    ></div>
                  </div>
                  <div className="centralized-new-card">
                    <img
                      src={require(`../assets/compress/kucoin.webp`)}
                      alt=""
                    />
                    <div>
                      <h6>Kucoin</h6>
                    </div>{" "}
                    <div
                      className="clickable-overlay"
                      data-value="KUCOIN"
                      onClick={(e) => {
                        setExchange(e); // Set the exchange
                        handleOpen(); // Open the modal
                      }}
                    ></div>
                  </div>
                  <div className="centralized-new-card">
                    <img src={require(`../assets/compress/mexc.webp`)} alt="" />
                    <div>
                      <h6>Mexc</h6>
                    </div>{" "}
                    <div
                      className="clickable-overlay"
                      data-value="MEXC"
                      onClick={(e) => {
                        setExchange(e); // Set the exchange
                        handleOpen(); // Open the modal
                      }}
                    ></div>
                  </div>
                  <div className="centralized-new-card">
                    <img src={require(`../assets/compress/okx.webp`)} alt="" />
                    <div>
                      <h6>OKX</h6>
                    </div>{" "}
                    <div
                      className="clickable-overlay"
                      data-value="OKX"
                      onClick={(e) => {
                        setExchange(e); // Set the exchange
                        handleOpen(); // Open the modal
                      }}
                    ></div>
                  </div>
                  <div>
                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={open}
                      onClose={handleClose}
                      closeAfterTransition
                      slots={{ backdrop: Backdrop }}
                      slotProps={{
                        backdrop: {
                          timeout: 500,
                        },
                      }}
                    >
                      <Fade in={open}>
                        <Box sx={style} className="connect-modal">
                          <div className="modal_main_cls">
                            <div className="connect-modal-head">
                              <span onClick={handleClose}>
                                <i class="fa-solid fa-xmark"></i>
                              </span>
                              <div
                                className="modal-top"
                                onClick={() => addFormFields()}
                              >
                                <img
                                  src={require("../assets/compress/plus.webp")}
                                  alt="plus"
                                />
                                <span>Add</span>
                              </div>
                            </div>
                            {formValuesref.current.length > 0
                              ? formValuesref.current.map((element, index) => (
                                  <div className="connect-modal-main mt-3">
                                    <div className="connect-modal-input">
                                      <label htmlFor="api-key">API Key</label>
                                      <input
                                        type="text"
                                        placeholder="MdcghbMsgdasgjMjbas..."
                                        id="api-key"
                                        name="apikey"
                                        value={element.apikey || ""}
                                        onChange={(e) => handleChange(index, e)}
                                      />
                                    </div>
                                    {apikeyValidate == true ? (
                                      validationnErr.index == index ? (
                                        <p className="errorcss">
                                          {" "}
                                          {validationnErr.apikey}{" "}
                                        </p>
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      ""
                                    )}
                                    <div className="connect-modal-input">
                                      <label htmlFor="secret-key">
                                        Secret Key
                                      </label>
                                      <input
                                        type="text"
                                        placeholder="NhgfjjMhgjhfLyfftufuygdj..."
                                        id="secret-key"
                                        name="secretkey"
                                        value={element.secretkey || ""}
                                        onChange={(e) => handleChange(index, e)}
                                      />
                                    </div>
                                    {secretkeyValidate == true ? (
                                      validationnErr.index == index ? (
                                        <p className="errorcss">
                                          {" "}
                                          {validationnErr.secretkey}{" "}
                                        </p>
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      ""
                                    )}
                                    <div className="connect-modal-input">
                                      <label htmlFor="api-key">
                                        Passphrase
                                      </label>
                                      <input
                                        type="text"
                                        placeholder="Jsfg65djWjhbd556ffg..."
                                        id="api-key"
                                        name="passphrase"
                                        value={element.passphrase || ""}
                                        onChange={(e) => handleChange(index, e)}
                                      />
                                    </div>
                                    <div key={index} className="cnctadd-remove">
                                      {index ? (
                                        <p
                                          class="modal-title"
                                          id="exampleModalLongTitle"
                                        >
                                          <div
                                            className="modal-head-add"
                                            onClick={() =>
                                              removeFormFields(index)
                                            }
                                          >
                                            <img
                                              src={require("../assets/Hover-img-remove.png")}
                                              alt="remove icon"
                                              className="modal-add-btn"
                                            />
                                            <span className="modal-remove-txt">
                                              {" "}
                                              Remove
                                            </span>
                                          </div>
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>
                                ))
                              : ""}
                            {buttonLoader == false ? (
                              <button
                                className="primary-btn w-100 mt-4"
                                onClick={formSubmit}
                              >
                                Submit
                              </button>
                            ) : (
                              <button className="primary-btn w-100 mt-4">
                                Loading ...
                              </button>
                            )}
                          </div>
                        </Box>
                      </Fade>
                    </Modal>
                  </div>
                </div>
              </div>
              <div className="auto-bot-wrapper profit-mar-top mt-5">
                <div className="auto-top profit-top bor-bottom ">
                  <div className="d-flex gap-3 align-items-center">
                    <img
                      src={LAST}
                      alt="balance-history"
                      className="ref-profit-img"
                    />
                    <span className="ref-profit">De-Centralized Exchange</span>
                  </div>
                </div>

                <div className="centralized-wrapper">
                  {/* {decentralizeExchange.map((data) => {
                    return <Decentralized key={data.id} data={data} />;
                  })} */}
                  <div className="centralized-card">
                    <img
                      src={require(`../assets/compress/uniswap.webp`)}
                      alt="exchange-logo"
                    />
                    <div>
                      <h6>Uniswap V3</h6>
                    </div>
                    <div
                      className="clickable-overlay"
                      onClick={handleDecentraOpen}
                    ></div>
                  </div>
                  <div className="centralized-card">
                    <img
                      src={require(`../assets/compress/balancer.webp`)}
                      alt="Balancer"
                    />
                    <div>
                      <h6>Balancer</h6>
                    </div>
                    <div
                      className="clickable-overlay"
                      onClick={handleDecentraOpen}
                    ></div>
                  </div>
                  <div className="centralized-card">
                    <img
                      src={require(`../assets/compress/sushiswap.webp`)}
                      alt="Sushiswap"
                    />
                    <div>
                      <h6>Sushiswap</h6>
                    </div>
                    <div
                      className="clickable-overlay"
                      onClick={handleDecentraOpen}
                    ></div>
                  </div>
                  <div className="centralized-card">
                    <img
                      src={require(`../assets/compress/quickswap.webp`)}
                      alt="Quickswap"
                    />
                    <div>
                      <h6>Quickswap</h6>
                    </div>
                    <div
                      className="clickable-overlay"
                      onClick={handleDecentraOpen}
                    ></div>
                  </div>
                  <div className="centralized-card">
                    <img
                      src={require(`../assets/compress/curve.webp`)}
                      alt="Curve"
                    />
                    <div>
                      <h6>Curve</h6>
                    </div>
                    <div
                      className="clickable-overlay"
                      onClick={handleDecentraOpen}
                    ></div>
                  </div>
                  <div className="centralized-card">
                    <img
                      src={require(`../assets/compress/Polygon.webp`)}
                      alt="Polygon"
                    />
                    <div>
                      <h6>Polygon</h6>
                    </div>
                    <div
                      className="clickable-overlay"
                      onClick={handleDecentraOpen}
                    ></div>
                  </div>

                  <div>
                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={isDecentraOpen}
                      onClose={handleDecentraClose}
                      closeAfterTransition
                      slots={{ backdrop: Backdrop }}
                      slotProps={{
                        backdrop: {
                          timeout: 500,
                        },
                      }}
                    >
                      <Fade in={isDecentraOpen}>
                        <Box sx={style} className="connect-modal">
                          <div>
                            <div className="connect-modal-head">
                              <span onClick={handleDecentraClose}>
                                <i class="fa-solid fa-xmark"></i>
                              </span>
                              <div className="modal-top">
                                <img
                                  src={require("../assets/compress/plus.webp")}
                                  alt="plus"
                                />
                                <span>Add</span>
                              </div>
                            </div>

                            <div className="connect-modal-main">
                              <div className="connect-modal-input">
                                <label htmlFor="api-key">API Key</label>
                                <input
                                  type="text"
                                  placeholder="MdcghbMsgdasgjMjbas..."
                                  id="api-key"
                                />
                              </div>
                              <div className="connect-modal-input">
                                <label htmlFor="secret-key">Secret Key</label>
                                <input
                                  type="text"
                                  placeholder="NhgfjjMhgjhfLyfftufuygdj..."
                                  id="secret-key"
                                />
                              </div>
                              <button className="primary-btn w-100 mt-4">
                                Submit
                              </button>
                            </div>
                          </div>
                        </Box>
                      </Fade>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConnectExchange;
