import SWAP_ARROW from "../assets/compress/swap.webp";

const OpportunityTable = ({ data }) => {
  return (
    <div className="opportunity-cards">
      <div className="opp-header d-flex align-items-center justify-content-center bor-bottom gap-3">
        <h6 className="opp-name">{data.name}</h6>
        <h5 className="opt-percent">{data.percent}</h5>
      </div>

      <div className="opp-main">
        <div className="bor-bottom d-flex justify-content-between align-items-center opp-pad-bot">
          <div className="d-flex flex-column align-items-center gap-2">
            <img
              src={require(`../assets/compress/${data.img}`)}
              alt="bitcoin"
              className="swap-img"
            />
            <p className="opp-name mt-1">${data.swapAmtOne}</p>
          </div>
          <div>
            <img src={SWAP_ARROW} alt="" />
          </div>
          <div className="d-flex flex-column align-items-center gap-2">
            <img
              src={require(`../assets/compress/${data.img}`)}
              alt="bitcoin"
              className="swap-img"
            />
            <p className="opp-name mt-1">${data.swapAmtTwo}</p>
          </div>
        </div>
      </div>

      <div className="opp-foot d-flex justify-content-between">
        <h6 className="opp-price-title">Price:</h6>
        <h6 className="opp-price">${data.price}</h6>
      </div>
    </div>
  );
};

export default OpportunityTable;
