import React, { useEffect } from "react";
import useState from "react-usestateref";
import LINEAR_BG from "../assets/compress/linear-bg-primary.webp";
import LAST from "../assets/compress/trade-history.webp";
import Header from "./Header";
import LoginHisTable from "./LoginHisTable";
import Sidebar from "./Sidebar";
import TradeHisTable from "./TradeHisTable";
import ReactPaginate from "react-paginate";
import apiService from "../core/sevice/detail";
import { getMethod, postMethod } from "../core/sevice/common.api";
import moment from "moment";
import { socket } from "../core/context/socket";
import toast, { Toaster } from "react-hot-toast";
import * as XLSX from "xlsx";

const LoginHistory = () => {
  const [buttonLoader, setButtonLoader] = useState(false);
  const [adminDetails, setAdminDetails, adminDetailsref] = useState("");
  const [adminAllDetails, setadminAllDetails, adminAllDetailsref] =
    useState("");
  const [loginCounts, setloginCounts, loginCountsref] = useState(0);

  useEffect(() => {
    getLoginHistory(1);
  }, []);

  const getLoginHistory = async (currentPage) => {
    let count = currentPage == undefined ? 1 : currentPage;
    try {
      var payload = {
        perPage: 10,
        page: count,
      };
      var data = {
        apiUrl: apiService.getadminlogin,
        payload: payload,
      };
      setButtonLoader(true);
      var resp = await postMethod(data);
      setButtonLoader(false);
      if (resp) {
        console.log(resp, "admin login details");
        setAdminDetails(resp.data);
        setloginCounts(resp.count);
      }
    } catch (error) {
      //   setIsLoading(false);
    }
  };

  const handlePageClick_trade = async (data) => {
    console.log(data.selected);
    let currentPage = data.selected + 1;
    getLoginHistory(currentPage);
  };

  const downloadData = async () => {
    try {
      var data = {
        apiUrl: apiService.getalladminlogin,
      };
      var resp = await getMethod(data);
      if (resp.status == true) {
        setadminAllDetails(resp.data);
        const fieldsToExport = [
          "date",
          "OS",
          "ipAddress",
          "platform",
          "browser",
        ];
        // console.log("Admin Details:", adminAllDetailsref.current);
        const filteredData = adminAllDetailsref.current.map((item) => {
          const filteredItem = {};
          fieldsToExport.forEach((field) => {
            filteredItem[field] = item[field];
          });
          // console.log("Filtered Item:", filteredItem);
          return filteredItem;
        });
        // console.log("Filtered Data:", filteredData);
        const worksheet = XLSX.utils.json_to_sheet(filteredData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "LoginHistory.xlsx");

        toast.success("File Downloaded");
      }
    } catch (error) {}
  };

  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          duration: 4000,
        }}
      />
      <section>
        <Header />
      </section>

      <div className="container-fluid enzo-wrapper">
        <div className="row ">
          <div className="col-lg-2 pad-left-0 max-md-none bor-right max-d-none">
            <Sidebar />
          </div>

          <div className="col-lg-10 pad-left-0 mar-bot">
            <div className="dashboard_content">
              <img src={LINEAR_BG} alt="linear-bg" />
            </div>
            <div className="amt-grid">
              <div className="auto-bot-wrapper profit-mar-top">
                <div className="auto-top profit-top bor-bottom ">
                  <div className="d-flex gap-3 align-items-center">
                    <img
                      src={LAST}
                      alt="balance-history"
                      className="ref-profit-img"
                    />
                    <span className="ref-profit">Login History</span>
                  </div>
                  <div className="profit-right-search">
                    {/* <div className="profit-search-wrapper">
                      <input type="text" placeholder="Search" />
                      <span>
                        <i class="fa-solid fa-magnifying-glass"></i>
                      </span>
                    </div> */}
                    <div className="profit-download-wrapper">
                      <img
                        src={require("../assets/profit-download.png")}
                        alt="profit-download"
                        onClick={downloadData}
                      />
                    </div>
                  </div>
                </div>

                <div className="auto-table">
                  {/* <LoginHisTable /> */}

                  <div className="table-container">
                    <table>
                      <thead>
                        <tr className="profit-thead-row">
                          <th> Date & Time</th>
                          <th>OS</th>
                          <th> IP Address</th>
                          <th>Device</th>
                          <th>Browser</th>
                          <th>Status</th>
                        </tr>
                      </thead>

                      <tbody>
                        {buttonLoader == true ? (
                          <tr className="text-center">
                            <td colSpan={10}>
                              <div className="loader-css-table">
                                <img
                                  src={require("../assets/Dual Ball@1x-1.0s-200px-200px (2).gif")}
                                  alt="loader"
                                  className="loader-gif"
                                />
                              </div>
                            </td>
                          </tr>
                        ) : (
                          <>
                            {adminDetailsref.current.length > 0 ? (
                              <>
                                {adminDetailsref.current.map((item) => (
                                  <tr className="profit-tbody-row">
                                    <td> {item.date}</td>
                                    <td>{item.OS}</td>
                                    <td> {item.ipAddress}</td>
                                    <td>{item.platform}</td>
                                    <td>{item.browser}</td>
                                    <td className="clr-green"> Successful</td>
                                  </tr>
                                ))}
                              </>
                            ) : (
                              <tr>
                                <td colSpan={6} className="text-center py-5">
                                  <div className="empty_data">
                                    <div className="empty_data_img">
                                      <img
                                        src={require("../assets/No-data.webp")}
                                        width="100px"
                                      />
                                    </div>
                                    <div className="no_records_text">
                                      No Records Found
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                        {adminDetailsref.current.length > 0 ? (
                          <tr className="text-center text-white pagi_Btm_borno">
                            <td colSpan="12">
                              <div className="paginationcss">
                                <ReactPaginate
                                  previousLabel={"<"}
                                  nextLabel={">"}
                                  breakLabel={"**"}
                                  pageCount={loginCountsref.current / 10}
                                  marginPagesDisplayed={1}
                                  pageRangeDisplayed={0}
                                  onPageChange={handlePageClick_trade}
                                  containerClassName={
                                    "pagination pagination-md justify-content-center custom-pagination"
                                  }
                                  pageClassName={"page-item"}
                                  pageLinkClassName={"page-link"}
                                  previousClassName={"page-item"}
                                  previousLinkClassName={"page-link"}
                                  nextClassName={"page-item"}
                                  nextLinkClassName={"page-link"}
                                  breakClassName={"page-item"}
                                  breakLinkClassName={"page-link"}
                                  activeClassName={"active"}
                                />
                              </div>
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginHistory;
