import React from "react";
import { tradeHistory } from "../utils/mockData";
import { Pagination, Stack } from "@mui/material";

const TradeHisTable = () => {
  return (
    <div className="table-container">
      <table>
        <thead>
          <tr className="profit-thead-row">
            <th>Pair</th>
            <th>Buy Exchange</th>
            <th>Sell Exchange</th>
            <th>Buy Amount</th>
            <th>Sell Amount</th>
            <th>Profit</th>
            <th>Strategy</th>
            <th>Date</th>
          </tr>
        </thead>

        <tbody>
          {tradeHistory.map((profit) => {
            return (
              <tr className="profit-tbody-row">
                <td>{profit.pair}</td>
                <td>{profit.buyExchange}</td>
                <td>{profit.sellExchange}</td>
                <td>{profit.buyAmount}</td>
                <td>{profit.sellAmount}</td>
                <td>{profit.profit}</td>
                <td>{profit.strategy}</td>
                <td>{profit.date}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* <div className="paginate">
        <span className="paginate-left-arrow">
          <i class="fa-solid fa-arrow-left"></i>
        </span>
        <span className="page-one">1</span>
        <span className="page-two">2</span>
        <span className="paginate-right-arrow">
          <i class="fa-solid fa-arrow-right"></i>
        </span>
      </div> */}{" "}
      <div className="pagination">
        <Stack spacing={2}>
          <Pagination
            // count={Math.ceil(total / recordPerPage)}
            // page={currentPage}
            // onChange={handlePageChange}
            size="small"
            sx={{
              "& .MuiPaginationItem-root": {
                color: "#fff",
              },
              "& .Mui-selected": {
                backgroundColor: "#FF593B !important",
                color: "#fff",

                "&:hover": {
                  backgroundColor: "#FF593B",
                  color: "#000",
                },
              },
              "& .MuiPaginationItem-ellipsis": {
                color: "#fff",
              },
              "& .MuiPaginationItem-icon": {
                color: "#fff", // Color for icon (if present)
              },
            }}
          />
        </Stack>
      </div>
    </div>
  );
};

export default TradeHisTable;
