import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";
import LINEAR_BG from "../assets/compress/linear-bg-primary.webp";
import { opportunityData } from "../utils/mockData";
import OpportunityTable from "./OpportunityTable";
import { socket } from "../core/context/socket";
import SWAP_ARROW from "../assets/compress/swap.webp";

const Opportunities = () => {
  const [opportunities, setopportunities, opportunitiesref] = useState([]);
  const [opportunities2, setopportunities2, opportunitiesref2] = useState([]);
  const [opportunities3, setopportunities3, opportunitiesref3] = useState([]);
  const [opportunities4, setopportunities4, opportunitiesref4] = useState([]);
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    // let socket_token = sessionStorage.getItem("socket_token");
    // let socketsplit = socket_token.split("_");
    socket.connect();
    socket.emit("GetProfitAllData");
    setIsLoader(true);
    socket.on("ProfitData", async (response) => {
      
      //console.log("profit data", response);
      if (response != null) {
        if (response.data.length > 0) {
          var opportunity_data = response.data.slice(0,4);
          setopportunities(opportunity_data);

          var opportunity_data2 = response.data.slice(4,8);
          setopportunities2(opportunity_data2);

          var opportunity_data3 = response.data.slice(8,12);
          setopportunities3(opportunity_data3);

          var opportunity_data4 = response.data.slice(12,16);
          setopportunities4(opportunity_data4);

          setIsLoader(false);
        } else {
          setIsLoader(false);
        }
      }
    });
  }, [0]);

  useEffect(() => {
    return () => {
      socket.disconnect();
    };
  }, []);


  return (
    <>
      <section>
        <Header />
      </section>

      <div className="container-fluid enzo-wrapper">
        <div className="row ">
          <div className="col-lg-2 pad-left-0 max-md-none bor-right max-d-none">
            <Sidebar />
          </div>

          <div className="col-lg-10 pad-left-0 mar-bot">
          {isLoader == true ? (
              <div className="loader-css">
              <img
                src={require("../assets/Dual Ball@1x-1.0s-200px-200px.gif")}
                alt="loader"
                className="loader-gif"
              />
            </div>
          ) : (
            <>
            <div className="dashboard_content ">
              <img src={LINEAR_BG} alt="linear-bg" />
            </div>

            <div className="amt-grid">
              <h5 className="opp-primary-title">Opportunities</h5>
            </div>
            <div className="card-wrapper">
            {isLoader == true ? (
               <div className="loader-css-table">
               <img
                 src={require("../assets/Dual Ball@1x-1.0s-200px-200px (2).gif")}
                 alt="loader"
                 className="loader-gif"
               />
             </div>
            ) : (
              <>
              {opportunitiesref.current.length > 0 ? (
                opportunitiesref.current.map((element, index) => {

                return (
                  <div className="opportunity-cards">
                  <div className="opp-header d-flex align-items-center justify-content-center bor-bottom gap-3">
                    <h6 className="opp-name">{element.pair}</h6>
                    <h5 className="opt-percent">+{parseFloat(element.profit_percent).toFixed(4)}%</h5>
                  </div>
            
                  <div className="opp-main">
                    <div className="bor-bottom d-flex justify-content-between align-items-center opp-pad-bot">
                      <div className="d-flex flex-column align-items-center gap-2">
                        <img
                          src={require(`../assets/exchange/${element.bid_exchange.toLowerCase()}.png`)}
                          alt="bitcoin"
                          className="swap-img"
                        />
                        <p className="opp-name mt-1">${element.bid_price}</p>
                      </div>
                      <div>
                        <img src={SWAP_ARROW} alt="" />
                      </div>
                      <div className="d-flex flex-column align-items-center gap-2">
                        <img
                          src={require(`../assets/exchange/${element.ask_exchange.toLowerCase()}.png`)}
                          alt="bitcoin"
                          className="swap-img"
                        />
                        <p className="opp-name mt-1">${element.ask_price}</p>
                      </div>
                    </div>
                  </div>
            
                  <div className="opp-foot d-flex justify-content-between">
                    <h6 className="opp-price-title">Price:</h6>
                    <h6 className="opp-price">${parseFloat(element.difference).toFixed(6)}</h6>
                  </div>
                </div>
                );
              })
              ) : (
                ""
              )}
              </>
            )}
              {/* {opportunityData.map((data) => {
                return <OpportunityTable key={data.id} data={data} />;
              })} */}
            </div>

            <div className="card-wrapper">
            {isLoader == true ? (
               <div className="loader-css-table">
               <img
                 src={require("../assets/Dual Ball@1x-1.0s-200px-200px (2).gif")}
                 alt="loader"
                 className="loader-gif"
               />
             </div>
            ) : (
              <>
              {opportunitiesref2.current.length > 0 ? (
                opportunitiesref2.current.map((element, index) => {

                return (
                  <div className="opportunity-cards">
                  <div className="opp-header d-flex align-items-center justify-content-center bor-bottom gap-3">
                    <h6 className="opp-name">{element.pair}</h6>
                    <h5 className="opt-percent">+{parseFloat(element.profit_percent).toFixed(4)}%</h5>
                  </div>
            
                  <div className="opp-main">
                    <div className="bor-bottom d-flex justify-content-between align-items-center opp-pad-bot">
                      <div className="d-flex flex-column align-items-center gap-2">
                        <img
                          src={require(`../assets/exchange/${element.bid_exchange.toLowerCase()}.png`)}
                          alt="bitcoin"
                          className="swap-img"
                        />
                        <p className="opp-name mt-1">${element.bid_price}</p>
                      </div>
                      <div>
                        <img src={SWAP_ARROW} alt="" />
                      </div>
                      <div className="d-flex flex-column align-items-center gap-2">
                        <img
                          src={require(`../assets/exchange/${element.ask_exchange.toLowerCase()}.png`)}
                          alt="bitcoin"
                          className="swap-img"
                        />
                        <p className="opp-name mt-1">${element.ask_price}</p>
                      </div>
                    </div>
                  </div>
            
                  <div className="opp-foot d-flex justify-content-between">
                    <h6 className="opp-price-title">Price:</h6>
                    <h6 className="opp-price">${parseFloat(element.difference).toFixed(6)}</h6>
                  </div>
                </div>
                );
              })
              ) : (
                ""
              )}
              </>
            )}
              {/* {opportunityData.map((data) => {
                return <OpportunityTable key={data.id} data={data} />;
              })} */}
            </div>

            <div className="card-wrapper">
            {isLoader == true ? (
               <div className="loader-css-table">
               <img
                 src={require("../assets/Dual Ball@1x-1.0s-200px-200px (2).gif")}
                 alt="loader"
                 className="loader-gif"
               />
             </div>
            ) : (
              <>
              {opportunitiesref3.current.length > 0 ? (
                opportunitiesref3.current.map((element, index) => {

                return (
                  <div className="opportunity-cards">
                  <div className="opp-header d-flex align-items-center justify-content-center bor-bottom gap-3">
                    <h6 className="opp-name">{element.pair}</h6>
                    <h5 className="opt-percent">+{parseFloat(element.profit_percent).toFixed(4)}%</h5>
                  </div>
            
                  <div className="opp-main">
                    <div className="bor-bottom d-flex justify-content-between align-items-center opp-pad-bot">
                      <div className="d-flex flex-column align-items-center gap-2">
                        <img
                          src={require(`../assets/exchange/${element.bid_exchange.toLowerCase()}.png`)}
                          alt="bitcoin"
                          className="swap-img"
                        />
                        <p className="opp-name mt-1">${element.bid_price}</p>
                      </div>
                      <div>
                        <img src={SWAP_ARROW} alt="" />
                      </div>
                      <div className="d-flex flex-column align-items-center gap-2">
                        <img
                          src={require(`../assets/exchange/${element.ask_exchange.toLowerCase()}.png`)}
                          alt="bitcoin"
                          className="swap-img"
                        />
                        <p className="opp-name mt-1">${element.ask_price}</p>
                      </div>
                    </div>
                  </div>
            
                  <div className="opp-foot d-flex justify-content-between">
                    <h6 className="opp-price-title">Price:</h6>
                    <h6 className="opp-price">${parseFloat(element.difference).toFixed(6)}</h6>
                  </div>
                </div>
                );
              })
              ) : (
                ""
              )}
              </>
            )}
              {/* {opportunityData.map((data) => {
                return <OpportunityTable key={data.id} data={data} />;
              })} */}
            </div>

            <div className="card-wrapper">
            {isLoader == true ? (
               <div className="loader-css-table">
               <img
                 src={require("../assets/Dual Ball@1x-1.0s-200px-200px (2).gif")}
                 alt="loader"
                 className="loader-gif"
               />
             </div>
            ) : (
              <>
              {opportunitiesref4.current.length > 0 ? (
                opportunitiesref4.current.map((element, index) => {

                return (
                  <div className="opportunity-cards">
                  <div className="opp-header d-flex align-items-center justify-content-center bor-bottom gap-3">
                    <h6 className="opp-name">{element.pair}</h6>
                    <h5 className="opt-percent">+{parseFloat(element.profit_percent).toFixed(4)}%</h5>
                  </div>
            
                  <div className="opp-main">
                    <div className="bor-bottom d-flex justify-content-between align-items-center opp-pad-bot">
                      <div className="d-flex flex-column align-items-center gap-2">
                        <img
                          src={require(`../assets/exchange/${element.bid_exchange.toLowerCase()}.png`)}
                          alt="bitcoin"
                          className="swap-img"
                        />
                        <p className="opp-name mt-1">${element.bid_price}</p>
                      </div>
                      <div>
                        <img src={SWAP_ARROW} alt="" />
                      </div>
                      <div className="d-flex flex-column align-items-center gap-2">
                        <img
                          src={require(`../assets/exchange/${element.ask_exchange.toLowerCase()}.png`)}
                          alt="bitcoin"
                          className="swap-img"
                        />
                        <p className="opp-name mt-1">${element.ask_price}</p>
                      </div>
                    </div>
                  </div>
            
                  <div className="opp-foot d-flex justify-content-between">
                    <h6 className="opp-price-title">Price:</h6>
                    <h6 className="opp-price">${parseFloat(element.difference).toFixed(6)}</h6>
                  </div>
                </div>
                );
              })
              ) : (
                ""
              )}
              </>
            )}
              {/* {opportunityData.map((data) => {
                return <OpportunityTable key={data.id} data={data} />;
              })} */}
            </div>

            </>
          )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Opportunities;
