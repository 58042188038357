import React, { useEffect } from "react";
import useState from "react-usestateref";
import LINEAR_BG from "../assets/compress/linear-bg-primary.webp";
import LAST from "../assets/compress/trade-history.webp";
import Header from "./Header";
import Sidebar from "./Sidebar";
import TradeHisTable from "./TradeHisTable";
import ReactPaginate from "react-paginate";
import apiService from "../core/sevice/detail";
import { getMethod, postMethod } from "../core/sevice/common.api";
import moment from "moment";
import { socket } from "../core/context/socket";
import toast, { Toaster } from "react-hot-toast";
import * as XLSX from "xlsx";

const TradeHistory = () => {
  const [filterStatus, setFilterStatus] = useState(false);
  const [isLoading, setIsLoading, isLoadingref] = useState(false);
  const [openOrders, setopenOrders, openOrdersref] = useState([]);
  const [CurrentPage, setCurrentPage, CurrentPageref] = useState(1);
  const [TotalPages, setTotalPages, TotalPagesref] = useState(0);
  const [search, setsearch, searchref] = useState("");

  const [search_trade, setsearch_trade, search_traderef] = useState("");
  const [tradeOrders, settradeOrders, tradeOrdersref] = useState([]);
  const [alltradeHistory, setalltradeHistory, alltradeHistoryref] = useState(
    []
  );
  const [TotalPages_trade, setTotalPages_trade, TotalPages_traderef] =
    useState(0);

  const [startDate, setstartDate, startDateref] = useState("");

  const [endDate, setendDate, endDateref] = useState("");

  const [type, setType, Typeref] = useState("open");

  useEffect(() => {
    // socket.connect();
    getTradeHistory(1);

    // socket.on("GetTradeOrders_user", async (response) => {
    //     //console.log("GetTradeOrders_user",response);
    //     if(response != null)
    //     {
    //      if(response.data.length > 0)
    //      {
    //         settradeOrders(response.data);
    //      }
    //     }
    //  });
  }, [0]);

  const setfilterDate = async (val, type) => {
    if (type == "start") {
      setstartDate(val);
    } else {
      setendDate(val);
    }
    console.log("date value", val);
    if (val != "") {
      getTradeHistory_filter(1);
    } else {
      getTradeHistory(1);
    }
  };

  const tradeorder_socket = async () => {
    socket.on("GetTradeOrders_user", async (response) => {
      //console.log("GetTradeOrders_user",response);
      if (response != null) {
        if (response.data.length > 0) {
          settradeOrders(response.data);
        }
      }
    });
  };

  const getTradeHistory_socket = async (currentPage) => {
    let count = currentPage == undefined ? 1 : currentPage;
    try {
      var payload = {
        perPage: 10,
        page: count,
        search: search_traderef.current,
        startdate: startDateref.current,
        enddate: endDateref.current,
      };
      console.log("payload", payload);
      socket.emit("GetTradeOrders", payload);
    } catch (error) {}
  };
  const getTradeHistory = async (currentPage) => {
    let count = currentPage == undefined ? 1 : currentPage;
    try {
      var payload = {
        perPage: 10,
        page: count,
        search: search_traderef.current,
        startdate: startDateref.current,
        enddate: endDateref.current,
      };
      var data = {
        apiUrl: apiService.getTradeHistory,
        payload: payload,
      };
      setIsLoading(true);
      var resp = await postMethod(data);
      console.log("trade history resp", resp);
      var order_resp = resp.data;
      setIsLoading(false);
      if (order_resp.status == true) {
        settradeOrders(order_resp.result);
        setTotalPages_trade(order_resp.pages);
      }

      setTimeout(async () => {
        getTradeHistory_socket(currentPage);
      }, 1000);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handlePageClick_trade = async (data) => {
    console.log(data.selected);
    let currentPage = data.selected + 1;
    getTradeHistory(currentPage);
  };

  const getTradeHistory_filter = async (currentPage) => {
    socket.off("GetTradeOrders_user");
    let count = currentPage == undefined ? 1 : currentPage;
    try {
      var payload = {
        perPage: 10,
        page: count,
        search: search_traderef.current,
        startdate: startDateref.current,
        enddate: endDateref.current,
      };
      var data = {
        apiUrl: apiService.getTradeHistory,
        payload: payload,
      };
      setIsLoading(true);
      var resp = await postMethod(data);
      console.log("trade history resp", resp);
      var order_resp = resp.data;
      setIsLoading(false);
      if (order_resp.status == true) {
        settradeOrders(order_resp.result);
        setTotalPages_trade(order_resp.pages);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    try {
      const sanitizedValue = e.target.value.replace(/\s/g, "");
      console.log(sanitizedValue, "-=-sanitizedValue=-=");
      setsearch_trade(sanitizedValue);
      // setsearch(e.target.value);
      searchTradeOrders();
    } catch (error) {}
  };

  const searchTradeOrders = async () => {
    if (search_traderef.current != "") {
      getTradeHistory_filter(1);
    } else {
      getTradeHistory(1);
      tradeorder_socket();
    }
  };

  const changeFilter = async (value) => {
    if (value == "open") {
      setFilterStatus(false);
      // getOpenOrders(1);
    } else {
      setFilterStatus(true);
      getTradeHistory(1);
    }
    setType(value);
  };

  const downloadData = async () => {
    try {
      var data = {
        apiUrl: apiService.getalltradehistory,
      };
      var resp = await getMethod(data);
      console.log(resp, "resprespresprespresp");
      if (resp.status == true) {
        setalltradeHistory(resp.data);
        const fieldsToExport = [
          "pair",
          "buy_exchange",
          "sell_exchange",
          "profit_amount",
          "strategy",
          "updatedAt",
        ];
        console.log("Admin Details:", alltradeHistoryref.current);
        const filteredData = alltradeHistoryref.current.map((item) => {
          const filteredItem = {};
          fieldsToExport.forEach((field) => {
            filteredItem[field] = item[field];
          });
          console.log("Filtered Item:", filteredItem);
          return filteredItem;
        });
        console.log("Filtered Data:", filteredData);
        const worksheet = XLSX.utils.json_to_sheet(filteredData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "TradeHistory.xlsx");

        toast.success("File Downloaded");
      }
    } catch (error) {}
  };

  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          duration: 4000,
        }}
      />
      <section>
        <Header />
      </section>

      <div className="container-fluid enzo-wrapper">
        <div className="row ">
          <div className="col-lg-2 pad-left-0 max-md-none bor-right max-d-none">
            <Sidebar />
          </div>

          <div className="col-lg-10 pad-left-0 mar-bot">
            <div className="dashboard_content">
              <img src={LINEAR_BG} alt="linear-bg" />
            </div>
            <div className="amt-grid">
              <div className="auto-bot-wrapper profit-mar-top">
                <div className="auto-top profit-top bor-bottom ">
                  <div className="d-flex gap-3 align-items-center">
                    <img
                      src={LAST}
                      alt="balance-history"
                      className="ref-profit-img"
                    />
                    <span className="ref-profit">Trade History</span>
                  </div>
                  <div className="profit-right-search">
                    <div className="profit-filter">
                      <div className="order-from">
                        <span className="order-from-fil">From </span>
                        <input
                          type="date"
                          placeholder="from"
                          className="order-from-inp"
                          onChange={(e) =>
                            setfilterDate(e.target.value, "start")
                          }
                        />
                      </div>
                      <div className="order-from">
                        <span className="order-from-fil">To </span>
                        <input
                          type="date"
                          placeholder="to"
                          className="order-from-inp"
                          onChange={(e) => setfilterDate(e.target.value, "end")}
                        />
                      </div>
                    </div>
                    <div className="profit-search-wrapper">
                      <input
                        type="text"
                        placeholder="Search"
                        onChange={handleChange}
                      />
                      <span>
                        <i
                          class="fa-solid fa-magnifying-glass"
                          onClick={searchTradeOrders}
                        ></i>
                      </span>
                    </div>
                    <div className="profit-download-wrapper">
                      <img
                        src={require("../assets/profit-download.png")}
                        alt="profit-download"
                        onClick={downloadData}
                      />
                    </div>
                  </div>
                </div>

                <div className="auto-table">
                  {/* <TradeHisTable /> */}

                  <div className="table-container">
                    <table>
                      <thead>
                        <tr className="profit-thead-row">
                          <th>Pair</th>
                          <th>Buy Exchange</th>
                          <th>Sell Exchange</th>
                          <th>Buy Amount</th>
                          <th>Sell Amount</th>
                          <th>Profit</th>
                          <th>Strategy</th>
                          <th>Date & Time</th>
                        </tr>
                      </thead>

                      <tbody>
                        {isLoadingref.current == true ? (
                          <tr className="text-center">
                            <td colSpan={8}>
                              <div className="loader-css-table">
                                <img
                                  src={require("../assets/Dual Ball@1x-1.0s-200px-200px (2).gif")}
                                  alt="loader"
                                  className="loader-gif"
                                />
                              </div>
                            </td>
                          </tr>
                        ) : (
                          <>
                            {tradeOrdersref.current.length > 0 ? (
                              tradeOrdersref.current.map((element, index) => {
                                var strategy =
                                  element.strategy == "intra" ||
                                  element.strategy == "dca"
                                    ? "Intra"
                                    : element.strategy;
                                return (
                                  <tr className="profit-tbody-row">
                                    <td>{element.pair}</td>
                                    <td>{element.buy_exchange}</td>
                                    <td>{element.sell_exchange}</td>
                                    <td>
                                      {parseFloat(
                                        element.buy_price * element.buy_quantity
                                      ).toFixed(6)}
                                    </td>
                                    <td>
                                      {parseFloat(
                                        element.sell_price *
                                          element.sell_quantity
                                      ).toFixed(6)}
                                    </td>
                                    <td>
                                      {parseFloat(
                                        element.profit_amount
                                      ).toFixed(6)}
                                    </td>
                                    <td>{element.strategy}</td>
                                    <td>
                                      {moment(element.updatedAt).format("lll")}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={12} className="text-center py-5">
                                  <div className="empty_data">
                                    <div className="empty_data_img">
                                      <img
                                        src={require("../assets/No-data.webp")}
                                        width="100px"
                                      />
                                    </div>
                                    <div className="no_records_text">
                                      No Records Found
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                        {tradeOrdersref.current.length > 0 ? (
                          <tr className="text-center text-white pagi_Btm_borno">
                            <td colSpan="12">
                              <div className="paginationcss">
                                <ReactPaginate
                                  previousLabel={"<"}
                                  nextLabel={">"}
                                  breakLabel={"**"}
                                  pageCount={TotalPages_traderef.current}
                                  marginPagesDisplayed={1}
                                  pageRangeDisplayed={0}
                                  onPageChange={handlePageClick_trade}
                                  containerClassName={
                                    "pagination pagination-md justify-content-center custom-pagination"
                                  }
                                  pageClassName={"page-item"}
                                  pageLinkClassName={"page-link"}
                                  previousClassName={"page-item"}
                                  previousLinkClassName={"page-link"}
                                  nextClassName={"page-item"}
                                  nextLinkClassName={"page-link"}
                                  breakClassName={"page-item"}
                                  breakLinkClassName={"page-link"}
                                  activeClassName={"active"}
                                />
                              </div>
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                      </tbody>
                    </table>

                    {/* <div className="paginate">
                      <span className="paginate-left-arrow">
                        <i class="fa-solid fa-arrow-left"></i>
                      </span>
                      <span className="page-one">1</span>
                      <span className="page-two">2</span>
                      <span className="paginate-right-arrow">
                        <i class="fa-solid fa-arrow-right"></i>
                      </span>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TradeHistory;
